/*
 * @Author: wenju.liu wenju.liu@softline.sh.cn
 * @Date: 2022-07-25 11:08:19
 * @LastEditors: wenju.liu wenju.liu@softline.sh.cn
 * @LastEditTime: 2022-10-21 14:25:30
 * @FilePath: /ikcest-c2c/src/config/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 根据当前mode获取Base Url 类型
const env = process.env.VUE_APP_BASE_URL_TYPE

// 根据VUE_APP_BASE_URL_TYPE获取BASE_URL
const getBaseUrl = (env) => {
  const env2 = process.env.VUE_APP_BASE_URL_TYPE
  
  switch (env) {
    case 'development':
      return {
        baseUrl: 'http://localhost:8866',
        loginUrl: 'https://www.ikcest.org/my/login.htm?returnUrl=',
        ikcestMasterStation: 'http://ikcest.org/',
        membershipCenterUrl: 'http://member.ikcest.org/',
        passportUrl: 'https://prod-passport-v2.ikcest.org/',
        dataUrl: 'http://data.ikcest-test.ikcest.org/',
        dataCenterUrl: 'http://datacenter.ikcest.org/',
        buryingPointUrl: 'http://spider.schedule.ikcest.org/',
        cloudUrl: 'http://192.168.1.226:8099/', // 数典云后台地址
        cloudFrontUrl: 'http://shudian-test.ikcest.org:1010/' // 数典云前台地址
      };
    case 'test':
      return {
        baseUrl: 'https://origination.ikcest-test.ikcest.org:9095',
        loginUrl: 'https://www.ikcest.org/my/login.htm?returnUrl=',
        ikcestMasterStation: 'https://www.ikcest.org/',
        membershipCenterUrl: 'http://member.ikcest-test.ikcest.org:9096/',
        passportUrl: 'https://prod-passport-v2.ikcest.org/',
        dataUrl: 'https://prod-data-v2.ikcest.org/',
        dataCenterUrl: 'http://datacenter.ikcest-test.ikcest.org:10000/',
        buryingPointUrl: 'http://spider.schedule.ikcest.org/',
        cloudUrl: 'http://192.168.1.226:8099/', // 数典云后台地址
        cloudFrontUrl: 'http://shudian-test.ikcest.org:1010/' // 数典云前台地址
      };
    case 'new':
      return {
        baseUrl: 'https://origination-v7.ikcest.org',
        loginUrl: 'https://www.ikcest.org/my/login.htm?returnUrl=',
        ikcestMasterStation: 'https://www.ikcest.org/',
        membershipCenterUrl: 'https://member.ikcest.org/',
        passportUrl: 'https://prod-passport-v2.ikcest.org/',
        dataUrl: 'https://prod-data-v2.ikcest.org/',
        dataCenterUrl: 'http://origination-v7.ikcest.org/datasets/',
        buryingPointUrl: 'https://spider.ikcest.org/',
        cloudUrl: 'https://bdterm.ikcest.org/bdterm/', // 数典云后台地址
        cloudFrontUrl: 'https://bdterm.ikcest.org//' // 数典云前台地址
      };
      case 'www':
      return {
        baseUrl: 'https://origination-v7.ikcest.org',
        loginUrl: 'https://www.ikcest.org/my/login.htm?returnUrl=',
        ikcestMasterStation: 'https://www.ikcest.org/',
        membershipCenterUrl: 'https://member.ikcest.org/',
        passportUrl: 'https://prod-passport-v2.ikcest.org/',
        dataUrl: 'https://prod-data-v2.ikcest.org/',
        dataCenterUrl: 'http://origination-v7.ikcest.org/datasets/',
        buryingPointUrl: 'https://spider.ikcest.org/',
        cloudUrl: 'https://bdterm.ikcest.org/bdterm/', // 数典云后台地址
        cloudFrontUrl: 'https://bdterm.ikcest.org//' // 数典云前台地址
      };
    case 'production':
      return {
        baseUrl: 'http://192.168.2.197:9899',
        loginUrl: 'https://web.ikcest-test.ikcest.org/my/login.htm?returnUrl=',
        ikcestMasterStation: 'http://web.ikcest-test.ikcest.org/',
        membershipCenterUrl: 'http://member.ikcest-test.ikcest.org:9096/',
        passportUrl: 'https://prod-passport-v2.ikcest.org/',
        dataUrl: 'http://data.ikcest-test.ikcest.org/',
        dataCenterUrl: 'http://datacenter.ikcest.org/',
        buryingPointUrl: 'http://spider.schedule.ikcest.org/',
        cloudUrl: 'http://192.168.1.226:8099/', // 数典云后台地址
        cloudFrontUrl: 'http://shudian-test.ikcest.org:1010/' // 数典云前台地址
      };
    case 'uat':
      return {
        baseUrl: 'https://origination.uat.ikcest.org',
        loginUrl: 'https://web.uat.ikcest.org/my/login.htm?returnUrl=',
        searchChartUrl: 'https://search.uat.ikcest.org/',
        ikcestMasterStation: 'https://web.uat.ikcest.org/',
        membershipCenterUrl: 'https://member.uat.ikcest.org/',
        passportUrl: 'https://passport.uat.ikcest.org/', // passport
        dataUrl: 'https://data.uat.ikcest.org/',
        dataCenterUrl: 'https://datacenter.uat.ikcest.org/',
        buryingPointUrl: 'https://spider.schedule.ikcest.org/',
        cloudUrl: 'https://bdterm.ikcest.org/bdterm/', // 数典云后台地址
        cloudFrontUrl: 'https://bdterm.ikcest.org/', // 数典云前台地址
        beltRoadUrl: 'https://belt.uat.ikcest.org',
        globalEngineerUrl: 'https://global-engineering.uat.ikcest.org',
        aiHomeDataUrl: 'https://origination.uat.ikcest.org', // AI 伦理测试环境
        formerHomeLayoutUrl: 'https://data.uat.ikcest.org/', // 七期首页页面布局接口
        formerHomeDataUrl: 'https://web.uat.ikcest.org/', // 七期首页数据接口
        videoCenterUrl: 'https://video-center.uat.ikcest.org/', // Trending videos
        centresUrl: 'https://link.uat.ikcest.org/',
        cTwoCUrl: 'https://c2c.uat.ikcest.org',
      };
    default:
      return {
        baseUrl: 'https://origination-v7.ikcest.org',
        loginUrl: 'https://www.ikcest.org/my/login.htm?returnUrl=',
        ikcestMasterStation: 'https://www.ikcest.org/',
        membershipCenterUrl: 'https://member.ikcest.org/',
        passportUrl: 'https://prod-passport-v2.ikcest.org/',
        dataUrl: 'https://prod-data-v2.ikcest.org/',
        dataCenterUrl: 'http://origination-v7.ikcest.org/datasets/',
        buryingPointUrl: 'https://spider.ikcest.org/',
        cloudUrl: 'https://bdterm.ikcest.org/bdterm/', // 数典云后台地址
        cloudFrontUrl: 'https://bdterm.ikcest.org//' // 数典云前台地址
      }
  }
};
const appConfig = getBaseUrl(env);
export default appConfig
