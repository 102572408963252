'use strict';
// xi
exports.__esModule = true;
exports.default = {
  Home: 'Inicio',
  Countries: 'CountriesIndex',
  Information: 'Information',
  CountriesAlong: 'Countries Along B&R',
  AcademicResearch: 'AcademicResearch',
  DataCenter: 'DataCenter',
  More: 'More',
  TheBeltandRoadIndex: 'The Beltand Road Index',
  PleaseSelect: 'Please Select',
  Login:'Login',
  Registerforfree:'Register for free',
  Logout:'Logout',
  IndexRanking: 'Index Ranking',
  Comparison: 'Comparison',

  signin:'Ingresar',
  signup:'Regístrese gratis',
  Chairs:'Sillas',
  Chair:'Sillas',
  Projects:'Proyectos',
  Category2Centres:'Centros de categoría 2',
  UNESCO_chairs:'Sillas UNESCO',
  ProgrammeProjects:'Proyectos del Programa',
  More:'Más',
  Chairs_Location_Map:'Mapa de Ubicación de Sillas',
  News:'Noticias',
  UNESCOCategory2Centres:'Centros de Categoría 2 de la UNESCO',
  Unitwin_Network:'Red Unitwin',
  UNESCO_Link:'Enlaces de la UNESCO',
  Detail:'Detalle',
  Location:'Ubicación',
  Publications:'Publicaciones',
  Updates_and_News:'Actualizaciones y Noticias',
  Showing:'Mostrando',
  Agreement_Date:'Fecha de acuerdo',
  Description:'Descripción',
  Contact:'Contacto',
  Objective:'Objetivo ',
  Vision:'Visión',
  Updates:'Actualizaciones',
  Website:'Sitio web',
  Estableshed:'Established',
  Year:'Año',
  Search:'Buscar',
  Country:'Pays',
  Reports:'Informes',
  Events:'Eventos',
  Centres:'Centros',
  UNESCO_Centres:'Centros UNESCO',
  Asie:'Asia',
  Europe:'Europa',
  America:'America',
  Africa:'África',
  Oceania:'Oceanía',
  Event_Map:'Mapa de Eventos',
  UNESCO_Links:'Enlaces de la UNESCO',
  Trending_News:'Noticias de Actualidad',
  Latest_News:'Últimas Noticias',
  Person_Profile:'Perfil de la persona ',
  Her_Tracks:'',
  search:'Buscar',
  el: {
    colorpicker: {
      confirm: 'OK',
      clear: 'Clear'
    },
    datepicker: {
      now: 'Now',
      today: 'Today',
      cancel: 'Cancel',
      clear: 'Clear',
      confirm: 'OK',
      selectDate: 'Select date',
      selectTime: 'Select time',
      startDate: 'Start Date',
      startTime: 'Start Time',
      endDate: 'End Date',
      endTime: 'End Time',
      prevYear: 'Previous Year',
      nextYear: 'Next Year',
      prevMonth: 'Previous Month',
      nextMonth: 'Next Month',
      year: '',
      month1: 'January',
      month2: 'February',
      month3: 'March',
      month4: 'April',
      month5: 'May',
      month6: 'June',
      month7: 'July',
      month8: 'August',
      month9: 'September',
      month10: 'October',
      month11: 'November',
      month12: 'December',
      week: 'week',
      weeks: {
        sun: 'Sun',
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat'
      },
      months: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        may: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Aug',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Dec'
      }
    },
    select: {
      loading: 'Loading',
      noMatch: 'No matching data',
      noData: 'No data',
      placeholder: 'Select'
    },
    cascader: {
      noMatch: 'No matching data',
      loading: 'Loading',
      placeholder: 'Select',
      noData: 'No data'
    },
    pagination: {
      goto: 'Go to',
      pagesize: '/page',
      total: 'Total {total}',
      pageClassifier: ''
    },
    messagebox: {
      title: 'Message',
      confirm: 'OK',
      cancel: 'Cancel',
      error: 'Illegal input'
    },
    upload: {
      deleteTip: 'press delete to remove',
      delete: 'Delete',
      preview: 'Preview',
      continue: 'Continue'
    },
    table: {
      emptyText: 'No Data',
      confirmFilter: 'Confirm',
      resetFilter: 'Reset',
      clearFilter: 'All',
      sumText: 'Sum'
    },
    tree: {
      emptyText: 'No Data'
    },
    transfer: {
      noMatch: 'No matching data',
      noData: 'No data',
      titles: ['List 1', 'List 2'], // to be translated
      filterPlaceholder: 'Enter keyword', // to be translated
      noCheckedFormat: '{total} items', // to be translated
      hasCheckedFormat: '{checked}/{total} checked' // to be translated
    },
    image: {
      error: 'FAILED'
    },
    pageHeader: {
      title: 'Back' // to be translated
    },
    popconfirm: {
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }
  },

  label: {
    UserCenter: "User Center", // 用户中心
  },
  // 按钮
  button: {
    ReadMore: "Read More",
    Ok: `Ok`, // 确定
    Cancel: "Cancel", // 取消
    Submit: "Submit", // 提交
    Edit: "Edit", // 编辑
    Add: "Add", // 新增
    Delete: "Delete", // 删除
    Hide: "Hide", // 隐藏
    Download: "Download", // 下载
    Copy: "Copy", // 复制
    Generate: "Generate", // 生成
    Zoom_in: "Zoom in", // 放大
    Zoom_out: "Zoom out", // 缩小
    Full: "Full", // 全屏
    Reset: "Reset", // 重置
    Search: "Search", // 搜索
    Clear: "Clear", // 清空
    Like: "Like", // 点赞
    Favorite: "Favorite", // 收藏
    Login: "Login", // 登录
    Logout: "Logout", // 退出
    Register_For_Free: "Register for free", // 免费注册
    Mobile: "Mobile", // 移动端
    Scan: "Scan this QR code on your phone to browse IKCEST", // 扫描手机上的二维码以浏览IKCEST
    More: "More", // 更多
    Print: "Print", // 打印
    Correct: "Correct" // 纠错
  },



};