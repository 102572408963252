<template>
  <div>
    <!-- Subscription开始 -->
    <!-- <div class="Subscription">
      <div>
        <a class="btn" @click="handleSubscribe">Subscribe</a>
        <img src="../assets/img/public/bottom.png" alt="" />
      </div>
    </div> -->
    <!-- Subscription结束 -->

    <div class="footer-wrap">
      <div class="footer-panel comment-content">
        <img
          src="../assets/img/footer/footer-logo.png"
          height="120px"
          width="222px"
        />
        <div class="footer-bottom">
          <div class="btn-wrap">
            <a
              @click="
                handleOpen('https://www.facebook.com/Ikcest-2061801994091155/')
              "
              class="social-f"
            ></a>
            <a
              @click="handleOpen('https://twitter.com/IKCESTikcest')"
              class="social-t"
            ></a>
            <a
              @click="
                handleOpen(
                  'https://www.weibo.com/6523298026/profile?rightmod=1&amp;wvr=6&amp;mod=personinfo&amp;is_all=1'
                )
              "
              class="social-w"
            ></a>
          </div>
          <p>
            ICP备案号:京ICP备14021735号-1 © 2008 - 2023 IKCEST All rights
            reserved
            <a @click="handleOpen('https://www.ikcest.org/sitemap_new.htm')"
              >Sitemap</a
            >
            <a @click="handleOpen('https://www.ikcest.org/article-17021.htm')"
              >Contact</a
            >
            <a @click="handleOpen('https://www.ikcest.org/article-601.htm')"
              >About IKCEST</a
            >
          </p>
        </div>
      </div>
    </div>
    <el-dialog
      v-if="dialogVisible"
      title="提示"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="600px"
    >
      <ALink :aUrl="linkUrl" @close="handleClose"></ALink>
    </el-dialog>
  </div>
</template>

<script>
import store from "../store";
import configServer from "@/config";

export default {
  name: "Footer",
  components: {
    ALink: () => import("./ALink"),
  },
  data() {
    return {
      dialogVisible: false,
      linkUrl: "22222",
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    handleOpen(value) {
      this.dialogVisible = true;
      this.linkUrl = value;
    },
    handleClose() {
      this.dialogVisible = false;
    },

    handleSubscribe() {
      // 获取passportd
      // if (store.getters.user_info && store.getters.user_info.id) {
      if (store.getters.userInfo && store.getters.userInfo.id) {
        let id = store.getters.userInfo.id;
        const params = {
          passport: id,
          category: "Artificial Intelligence",
        };
        let data = ikcestCategoryServer.beltAndRoaldSubscription(params);
        if (data.code == 200) {
          this.$message({
            message:
              "You have successfully subscribed the channel. You may check out the update in your personal center!",
            type: "success",
          });
        } else {
          this.$message.error("You have failed subscribed the channel!");
        }
      } else {
        this.$alert("You are not logged in, please log in first!", "Tips", {
          confirmButtonText: "Login",
          type: "warning",
          showClose: false,
          closeOnClickModal: false,
        })
          .then(() => {
            let current = window.location.href;
            window.location.href =
              configServer.loginUrl + encodeURIComponent(current);
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-wrap {
  width: 100%;
  min-width: 1130px;
  height: 160px;
  background-color: #333333;
  background-size: cover;
  min-width: 1200px;
}
.footer-panel {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  color: #999;
}
.footer-bottom {
  text-align: right;
  font-size: 14px;
}
.footer-bottom p {
  color: #fff;
}
.footer-bottom p a {
  margin-left: 20px;
  &:before {
    content: "|";
    margin-right: 10px;
    color: #fff;
  }
  color: #fff;
}
.btn-wrap {
  margin: 30px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.btn-wrap a {
  cursor: pointer;
  display: block;
  width: 42px;
  height: 42px;
  opacity: 0.5;
}
.btn-wrap .social-f {
  width: 26px;
  height: 26px;
  margin-right: 15px;
  background: url("../assets/img/footer/social-f.png") no-repeat 0 0;
  background-size: cover;
}
.btn-wrap .social-t {
  margin-right: 12px;
  width: 34px;
  height: 26px;
  background: url("../assets/img/footer/social-t.png") no-repeat 0 0;
  background-size: cover;
}
.btn-wrap .social-w {
  width: 34px;
  height: 28px;
  background: url("../assets/img/footer/social-w.png") no-repeat 0 0;
  background-size: cover;
}
.footer-bottom p {
  font-size: 12px !important;
}
.footer-bottom a {
  font-size: 12px !important;
}
</style>
