import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/News',
    name: 'News',
    component: () => import(/* webpackChunkName: "News" */ '../views/News.vue')
  },
  {
    path: '/Publications',
    name: 'Publications',
    component: () => import(/* webpackChunkName: "Publications" */ '../views/Publications.vue')
  },

  {
    path: '/Reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "Reports" */ '../views/Reports.vue')
  },
  {
    path: '/Events',
    name: 'Events',
    component: () => import(/* webpackChunkName: "Events" */ '../views/Events.vue')
  },

  {
    path: '/Centres',
    name: 'Centres',
    component: () => import(/* webpackChunkName: "Centres" */ '../views/Centres.vue')
  },

  {
    path: '/Articles',
    name: 'Articles',
    component: () => import(/* webpackChunkName: "Articles" */ '../views/Articles.vue')
  }
  
]
// vue-router报错Uncaught (in promise)及解决方法https://www.jianshu.com/p/573fb3106079开始
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// vue-router报错Uncaught (in promise)及解决方法结束
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to,from,next)=>{
//  console.log(to,"totototo")
//  console.log(from,"fromfromfromfromfrom")
//   next()
// })

export default router
