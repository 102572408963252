var addressCallback =[
  {
    address: "9 Rue Eugène Delacroix, 75016 Paris, France",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "9",
          short_name: "9",
          types: ["street_number"],
        },
        {
          long_name: "Rue Eugène Delacroix",
          short_name: "Rue Eugène Delacroix",
          types: ["route"],
        },
        {
          long_name: "Paris",
          short_name: "Paris",
          types: ["locality", "political"],
        },
        {
          long_name: "Paris",
          short_name: "75",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Île-de-France",
          short_name: "IDF",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "France",
          short_name: "FR",
          types: ["country", "political"],
        },
        {
          long_name: "75116",
          short_name: "75116",
          types: ["postal_code"],
        },
      ],
      formatted_address: "9 Rue Eugène Delacroix, 75116 Paris, France",
      geometry: {
        bounds: {
          Ba: {
            k: 48.8630381,
            j: 48.86304310000001,
          },
          ra: {
            j: 2.278085000000033,
            k: 2.2781018000000586,
          },
        },
        location: {
          k: 48.8630381,
          A: 2.2781018000000586,
        },
        location_type: "RANGE_INTERPOLATED",
        viewport: {
          Ba: {
            k: 48.8616916197085,
            j: 48.8643895802915,
          },
          ra: {
            j: 2.276744419708507,
            k: 2.2794423802914707,
          },
        },
      },
      partial_match: true,
      types: ["street_address"],
    },
    continent: "Europe",
    name: "UNESCO International Institute for Educational Planning",
    cetegory: "class-one",
    url: "http://www.unesco.org/iiep/",
    location: "Paris, France",
  },


  
  {
    address: "Feldbrunnenstraße 58, 20148 Hamburg, Germany",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "58",
          short_name: "58",
          types: ["street_number"],
        },
        {
          long_name: "Feldbrunnenstraße",
          short_name: "Feldbrunnenstraße",
          types: ["route"],
        },
        {
          long_name: "Rotherbaum",
          short_name: "Rotherbaum",
          types: ["sublocality", "political"],
        },
        {
          long_name: "Eimsbüttel",
          short_name: "Eimsbüttel",
          types: ["sublocality", "political"],
        },
        {
          long_name: "Hamburg",
          short_name: "Hamburg",
          types: ["locality", "political"],
        },
        {
          long_name: "Hamburg",
          short_name: "HH",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Germany",
          short_name: "DE",
          types: ["country", "political"],
        },
        {
          long_name: "20148",
          short_name: "20148",
          types: ["postal_code"],
        },
      ],
      formatted_address: "Feldbrunnenstraße 58, 20148 Hamburg, Germany",
      geometry: {
        location: {
          k: 53.56845,
          A: 9.99099000000001,
        },
        location_type: "ROOFTOP",
        viewport: {
          Ba: {
            k: 53.5671010197085,
            j: 53.56979898029149,
          },
          ra: {
            j: 9.989641019708529,
            k: 9.992338980291493,
          },
        },
      },
      types: ["street_address"],
    },
    continent: "Europe",
    name: "UNESCO Institute for Lifelong Learning",
    cetegory: "class-one",
    url: "https://uil.unesco.org/",
    location: "Hamburg, Germany",
  },
  {
    address: "Hermann-Ehlers-Straße 10, 53113 Bonn, Germany",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "10",
          short_name: "10",
          types: ["street_number"],
        },
        {
          long_name: "Hermann-Ehlers-Straße",
          short_name: "Hermann-Ehlers-Straße",
          types: ["route"],
        },
        {
          long_name: "Zentrum",
          short_name: "Zentrum",
          types: ["sublocality", "political"],
        },
        {
          long_name: "Bonn",
          short_name: "BN",
          types: ["locality", "political"],
        },
        {
          long_name: "Cologne",
          short_name: "K",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "North Rhine-Westphalia",
          short_name: "NRW",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Germany",
          short_name: "DE",
          types: ["country", "political"],
        },
        {
          long_name: "53113",
          short_name: "53113",
          types: ["postal_code"],
        },
      ],
      formatted_address: "Hermann-Ehlers-Straße 10, 53113 Bonn, Germany",
      geometry: {
        location: {
          k: 50.71835,
          A: 7.127470000000017,
        },
        location_type: "ROOFTOP",
        viewport: {
          Ba: {
            k: 50.7170010197085,
            j: 50.7196989802915,
          },
          ra: {
            j: 7.126121019708535,
            k: 7.1288189802914985,
          },
        },
      },
      types: ["street_address"],
    },
    continent: "Europe",
    name:
      "UNESCO International Centre for Technical and Vocational Education and Training",
    cetegory: "class-one",
    url: "https://unevoc.unesco.org/",
    location: "Bonn，Germany",
  },
  {
    address: "Westvest 7, 2611 AX Delft, Netherlands",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "7",
          short_name: "7",
          types: ["street_number"],
        },
        {
          long_name: "Westvest",
          short_name: "Westvest",
          types: ["route"],
        },
        {
          long_name: "Delft",
          short_name: "Delft",
          types: ["locality", "political"],
        },
        {
          long_name: "Delft",
          short_name: "Delft",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "South Holland",
          short_name: "ZH",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "The Netherlands",
          short_name: "NL",
          types: ["country", "political"],
        },
        {
          long_name: "2611 AX",
          short_name: "2611 AX",
          types: ["postal_code"],
        },
      ],
      formatted_address: "Westvest 7, 2611 AX Delft, The Netherlands",
      geometry: {
        location: {
          k: 52.0088671,
          A: 4.3567299999999705,
        },
        location_type: "ROOFTOP",
        viewport: {
          Ba: {
            k: 52.0075181197085,
            j: 52.0102160802915,
          },
          ra: {
            j: 4.355381019708489,
            k: 4.358078980291566,
          },
        },
      },
      types: ["street_address"],
    },
    continent: "Europe",
    name: "UNESCO-IHE Institute for Water Education",
    cetegory: "class-one",
    url: "http://www.un-ihe.org/",
    location: "Delft, Netherlands",
  },
  {
    address: "Strada Costiera, 11, Trieste, Italy",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "11",
          short_name: "11",
          types: ["street_number"],
        },
        {
          long_name: "Strada Costiera",
          short_name: "Strada Costiera",
          types: ["route"],
        },
        {
          long_name: "Miramare",
          short_name: "Miramare",
          types: ["sublocality", "political"],
        },
        {
          long_name: "Trieste",
          short_name: "Trieste",
          types: ["locality", "political"],
        },
        {
          long_name: "Trieste",
          short_name: "TS",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Friuli-Venezia Giulia",
          short_name: "Friuli-Venezia Giulia",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Italy",
          short_name: "IT",
          types: ["country", "political"],
        },
        {
          long_name: "34151",
          short_name: "34151",
          types: ["postal_code"],
        },
      ],
      formatted_address: "Strada Costiera, 11, 34151 Trieste, Italy",
      geometry: {
        location: {
          k: 45.7144379,
          A: 13.707527700000014,   //前
        },
        location_type: "ROOFTOP",
        viewport: {
          Ba: {
            k: 45.7130889197085,
            j: 45.7157868802915,
          },
          ra: {
            j: 13.706178719708532,
            k: 13.708876680291496,
          },
        },
      },
      partial_match: true,
      types: ["street_address"],
    },
    continent: "Europe",
    name: "International Centre for Theoretical Physics",
    cetegory: "class-one",
    url: "http://www.ictp.it/",
    location: "Trieste, Italy",
  },
  {
    address: "5255 avenue Decelles, 7th floor, Montreal, QC H3T 2B1, Canada",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "5255",
          short_name: "5255",
          types: ["street_number"],
        },
        {
          long_name: "University of Montreal",
          short_name: "University of Montreal",
          types: ["establishment"],
        },
        {
          long_name: "Decelles Avenue",
          short_name: "Decelles Ave",
          types: ["route"],
        },
        {
          long_name: "Côte-Des-Neiges—Notre-Dame-De-Grâce",
          short_name: "Côte-Des-Neiges—Notre-Dame-De-Grâce",
          types: ["sublocality", "political"],
        },
        {
          long_name: "Montreal",
          short_name: "Montreal",
          types: ["locality", "political"],
        },
        {
          long_name: "Montreal",
          short_name: "Montreal",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Quebec",
          short_name: "QC",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Canada",
          short_name: "CA",
          types: ["country", "political"],
        },
        {
          long_name: "H3T 2B1",
          short_name: "H3T 2B1",
          types: ["postal_code"],
        },
      ],
      formatted_address:
        "5255 Decelles Avenue, University of Montreal, Montreal, QC H3T 2B1, Canada",
      geometry: {
        location: {
          k: 45.4973849,
          A: -73.61857750000001,
        },
        location_type: "ROOFTOP",
        viewport: {
          Ba: {
            k: 45.4960359197085,
            j: 45.4987338802915,
          },
          ra: {
            j: -73.6199264802915,
            k: -73.61722851970848,
          },
        },
      },
      partial_match: true,
      types: ["street_address"],
    },
    continent: "America",
    name: "UNESCO Institute for Statistics",
    cetegory: "class-one",
    url: "http://uis.unesco.org/",
    location: " Montreal, Canada",
  },
  {
    address: "IBE, Geneva, (Switzerland)",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Geneva",
          short_name: "Geneva",
          types: ["locality", "political"],
        },
        {
          long_name: "Genève",
          short_name: "Genève",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Geneva",
          short_name: "GE",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Switzerland",
          short_name: "CH",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Geneva, Switzerland",
      geometry: {
        bounds: {
          Ba: {
            k: 46.17766,
            j: 46.23175,
          },
          ra: {
            j: 6.110320099999967,
            k: 6.175939999999969,
          },
        },
        location: {
          k: 46.1983922,
          A: 6.142296100000067,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 46.17766,
            j: 46.23175,
          },
          ra: {
            j: 6.110320099999967,
            k: 6.175939999999969,
          },
        },
      },
      partial_match: true,
      types: ["locality", "political"],
    },
    continent: "Europe",
    name: "UNESCO International Bureau of Education",
    cetegory: "class-one",
    url: "http://www.ibe.unesco.org/",
    location: "Geneva, Switzerland",
  },
  {
    address: "IITE, Moscow (Russian Federation)",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "ITE, vystavochnaya kompaniya",
          short_name: "ITE, vystavochnaya kompaniya",
          types: ["point_of_interest", "establishment"],
        },
        {
          long_name: "42, строение 2 а",
          short_name: "42, строение 2 а",
          types: ["street_number"],
        },
        {
          long_name: "ulitsa Shchepkina",
          short_name: "ul. Shchepkina",
          types: ["route"],
        },
        {
          long_name: "Central Administrative Okrug",
          short_name: "Central Administrative Okrug",
          types: ["sublocality", "political"],
        },
        {
          long_name: "Moscow",
          short_name: "Moscow",
          types: ["locality", "political"],
        },
        {
          long_name: "gorod Moskva",
          short_name: "g. Moskva",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Moscow",
          short_name: "Moscow",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Russia",
          short_name: "RU",
          types: ["country", "political"],
        },
        {
          long_name: "129110",
          short_name: "129110",
          types: ["postal_code"],
        },
      ],
      formatted_address:
        "ITE, vystavochnaya kompaniya, ulitsa Shchepkina, 42, строение 2 а, Moscow, Russia, 129110",
      geometry: {
        location: {
          k: 55.78382,
          A: 37.63042599999994,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 55.7824710197085,
            j: 55.7851689802915,
          },
          ra: {
            j: 37.62907701970846,
            k: 37.63177498029154,
          },
        },
      },
      partial_match: true,
      types: ["point_of_interest", "establishment"],
    },
    continent: "Europe",
    name: "UNESCO Institute for Information Technologies in Education",
    cetegory: "class-one",
    url: "https://iite.unesco.org/",
    location: "Moscow, Russian Federation",
  },
  {
    address: "IICBA, Addis Ababa (Ethiopia)",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Addis Ababa",
          short_name: "Addis Ababa",
          types: ["locality", "political"],
        },
        {
          long_name: "Addis Ababa",
          short_name: "Addis Ababa",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Ethiopia",
          short_name: "ET",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Addis Ababa, Ethiopia",
      geometry: {
        bounds: {
          Ba: {
            k: 8.8406988,
            j: 9.0856549,
          },
          ra: {
            j: 38.65608229999998,
            k: 38.900207300000034,
          },
        },
        location: {
          k: 8.9806034,
          A: 38.75776050000002,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 8.8406988,
            j: 9.0856549,
          },
          ra: {
            j: 38.65608229999998,
            k: 38.900207300000034,
          },
        },
      },
      partial_match: true,
      types: ["locality", "political"],
    },
    continent: "Africa",
    name: "UNESCO International Institute for Capacity-Building in Africa",
    cetegory: "class-one",
    url: "http://www.iicba.unesco.org/",
    location: " Addis Ababa, Ethiopia",
  },
  {
    address: "IESALC, Caracas (Venezuela)",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Caracas",
          short_name: "CCS",
          types: ["locality", "political"],
        },
        {
          long_name: "Libertador",
          short_name: "Libertador",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Caracas Metropolitan District",
          short_name: "Dto. Capital",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Venezuela",
          short_name: "VE",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Caracas, Venezuela",
      geometry: {
        bounds: {
          Ba: {
            k: 10.39665,
            j: 10.5401335,
          },
          ra: {
            j: -67.06277840000001,
            k: -66.71795329999998,
          },
        },
        location: {
          k: 10.5,
          A: -66.91666670000001,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 10.39665,
            j: 10.5401335,
          },
          ra: {
            j: -67.06277840000001,
            k: -66.71795329999998,
          },
        },
      },
      partial_match: true,
      types: ["locality", "political"],
    },
    continent: "America",
    name:
      "UNESCO International Institute for Higher Education in Latin America and the Caribbean",
    cetegory: "class-one",
    url: "https://www.iesalc.unesco.org/en/",
    location: "Caracas, Venezuela",
  },
  {
    address: "120, Saemal-ro, Guro-gu, Seoul, Republic of Korea, 152-050",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "120",
          short_name: "120",
          types: ["political"],
        },
        {
          long_name: "Saemal-ro",
          short_name: "Saemal-ro",
          types: ["sublocality", "political"],
        },
        {
          long_name: "Guro-gu",
          short_name: "Guro-gu",
          types: ["sublocality", "political"],
        },
        {
          long_name: "Seoul",
          short_name: "Seoul",
          types: ["locality", "political"],
        },
        {
          long_name: "South Korea",
          short_name: "KR",
          types: ["country", "political"],
        },
        {
          long_name: "152-050",
          short_name: "152-050",
          types: ["postal_code"],
        },
      ],
      formatted_address: "120 Saemal-ro, Guro-gu, Seoul, South Korea",
      geometry: {
        location: {
          k: 37.5062465,
          A: 126.89279880000004,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 37.4973945,
            j: 37.5150975,
          },
          ra: {
            j: 126.8767914,
            k: 126.90880620000007,
          },
        },
      },
      types: ["political"],
    },
    continent: "Asia",
    name: "Asia-Pacific Centre of Education for International Understanding",
    cetegory: "Education",
    url: "http://www.unescoapceiu.org/",
    location: " Seoul, Republic of Korea",
  },
  {
    address: "1318 Ouagadougou 01",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "rue 7.01",
          short_name: "rue 7.01",
          types: ["route"],
        },
        {
          long_name: "Ouagadougou",
          short_name: "Ouagadougou",
          types: ["locality", "political"],
        },
        {
          long_name: "Kadiogo",
          short_name: "Kadiogo",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Centre",
          short_name: "Centre",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Burkina Faso",
          short_name: "BF",
          types: ["country", "political"],
        },
      ],
      formatted_address: "rue 7.01, Ouagadougou, Burkina Faso",
      geometry: {
        bounds: {
          Ba: {
            k: 12.3506121,
            j: 12.3593166,
          },
          ra: {
            j: -1.5356987000000117,
            k: -1.5341929999999593,
          },
        },
        location: {
          k: 12.3546189,
          A: -1.5349046000000044,
        },
        location_type: "GEOMETRIC_CENTER",
        viewport: {
          Ba: {
            k: 12.3506121,
            j: 12.3593166,
          },
          ra: {
            j: -1.5362948302914674,
            k: -1.5335968697085036,
          },
        },
      },
      partial_match: true,
      types: ["route"],
    },
    continent: "Africa",
    name: "International Centre for Girls and Women’s Education in Africa",
    cetegory: "Education",
    url: "http://cieffa.org/",
    location: "Ouagadougou, Burkina Faso",
  },
  {
    address: "Lilongwe 3, Malawi Area?? Lilongwe Malawi",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Lilongwe",
          short_name: "Lilongwe",
          types: ["natural_feature", "establishment"],
        },
        {
          long_name: "Lilongwe",
          short_name: "Lilongwe",
          types: ["locality", "political"],
        },
        {
          long_name: "Lilongwe",
          short_name: "Lilongwe",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Central Region",
          short_name: "Central Region",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Malawi",
          short_name: "MW",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Lilongwe, Malawi",
      geometry: {
        bounds: {
          Ba: {
            k: -13.9862103,
            j: -13.9815713,
          },
          ra: {
            j: 33.78422360000002,
            k: 33.793123700000024,
          },
        },
        location: {
          k: -13.9855232,
          A: 33.78633830000001,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: -13.9862103,
            j: -13.9815713,
          },
          ra: {
            j: 33.78422360000002,
            k: 33.793123700000024,
          },
        },
      },
      partial_match: true,
      types: ["natural_feature", "establishment"],
    },
    continent: "Africa",
    name: "Guidance, Counselling and Youth Development Centre for Africa",
    cetegory: "Education",
    url: "https://uia.org/s/or/en/1122278040",
    location: "Lilongwe, Malawi",
  },
  {
    address: "Haidian District, Beijing 100875, PRC",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name:
            "Agricultural Product Quantity Inspection & Testing Center, Ministry of Agriculture of P.R.C.",
          short_name:
            "Agricultural Product Quantity Inspection & Testing Center, Ministry of Agriculture of P.R.C.",
          types: ["point_of_interest", "establishment"],
        },
        {
          long_name: "59号",
          short_name: "59号",
          types: ["street_number"],
        },
        {
          long_name: "Xueyuan South Road",
          short_name: "Xueyuan S Rd",
          types: ["route"],
        },
        {
          long_name: "Beixiaguan",
          short_name: "Beixiaguan",
          types: ["neighborhood", "political"],
        },
        {
          long_name: "Haidian",
          short_name: "Haidian",
          types: ["sublocality", "political"],
        },
        {
          long_name: "Beijing",
          short_name: "Beijing",
          types: ["locality", "political"],
        },
        {
          long_name: "Beijing",
          short_name: "Beijing",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "China",
          short_name: "CN",
          types: ["country", "political"],
        },
      ],
      formatted_address:
        "Agricultural Product Quantity Inspection & Testing Center, Ministry of Agriculture of P.R.C., 59 Xueyuan South Road, Beixiaguan, Haidian, Beijing, China",
      geometry: {
        location: {
          k: 39.962205,
          A: 116.36559699999998,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 39.9563840197085,
            j: 39.9590819802915,
          },
          ra: {
            j: 116.33111401970848,
            k: 116.33381198029156,
          },
        },
      },
      partial_match: true,
      types: ["point_of_interest", "establishment"],
    },
    continent: "Asia",
    name: "International Research and Training Center for Rural Education",
    cetegory: "Education",
    url: "https://inruled.bnu.edu.cn/",
    location: "Beijing, China",
  },
  {
    address: "Sharjah University City",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "University City Road",
          short_name: "S 120",
          types: ["route"],
        },
        {
          long_name: "Sharjah",
          short_name: "Sharjah",
          types: ["locality", "political"],
        },
        {
          long_name: "Sharjah",
          short_name: "Sharjah",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "United Arab Emirates",
          short_name: "AE",
          types: ["country", "political"],
        },
      ],
      formatted_address:
        "University City Road - Sharjah - United Arab Emirates",
      geometry: {
        bounds: {
          Ba: {
            k: 25.2903692,
            j: 25.3435457,
          },
          ra: {
            j: 55.40518369999995,
            k: 55.49890579999999,
          },
        },
        location: {
          k: 25.3163503,
          A: 55.451464699999974,
        },
        location_type: "GEOMETRIC_CENTER",
        viewport: {
          Ba: {
            k: 25.2903692,
            j: 25.3435457,
          },
          ra: {
            j: 55.40518369999995,
            k: 55.49890579999999,
          },
        },
      },
      partial_match: true,
      types: ["route"],
    },
    continent: "Asia",
    name: "Regional Centre for Educational Planning",
    cetegory: "Education",
    url: "http://www.rcepunesco.ae/EN/Pages/default.aspx",
    location: "Sharjah, United Arab Emirates",
  },
  {
    address: "Philippines",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Philippines",
          short_name: "PH",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Philippines",
      geometry: {
        bounds: {
          Ba: {
            k: 4.613444299999999,
            j: 19.5740241,
          },
          ra: {
            j: 116.93155669999999,
            k: 126.60438369999997,
          },
        },
        location: {
          k: 12.879721,
          A: 121.77401699999996,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 4.613444299999999,
            j: 19.5740241,
          },
          ra: {
            j: 116.93511119999994,
            k: 126.60438369999997,
          },
        },
      },
      types: ["country", "political"],
    },
    continent: "Asia",
    name:
      "The South-East Asian Centre for Lifelong Learning for Sustainable Development",
    cetegory: "Education",
    url: "javascript:;",
    location: "Philippines",
  },
  {
    address: "Syrian Arab Republic",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Syria",
          short_name: "SY",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Syria",
      geometry: {
        bounds: {
          Ba: {
            k: 32.311136,
            j: 37.3205689,
          },
          ra: {
            j: 35.716595600000005,
            k: 42.37630899999999,
          },
        },
        location: {
          k: 34.80207499999999,
          A: 38.99681499999997,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 32.311136,
            j: 37.3205689,
          },
          ra: {
            j: 35.716595600000005,
            k: 42.37630899999999,
          },
        },
      },
      types: ["country", "political"],
    },
    continent: "Asia",
    name:
      "Regional Centre for Early Childhood Care and Education in the Arab States Damascus",
    cetegory: "Education",
    url: "javascript:;",
    location: "Syrian Arab Republic",
  },
  {
    address: "Sri Lanka",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Sri Lanka",
          short_name: "LK",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Sri Lanka",
      geometry: {
        bounds: {
          Ba: {
            k: 5.9190779,
            j: 9.836001,
          },
          ra: {
            j: 79.52198269999997,
            k: 81.87870290000001,
          },
        },
        location: {
          k: 7.873053999999999,
          A: 80.77179699999999,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 5.9190779,
            j: 9.836001,
          },
          ra: {
            j: 79.52198269999997,
            k: 81.87870290000001,
          },
        },
      },
      types: ["country", "political"],
    },
    continent: "Asia",
    name: "South Asian Centre for Teacher Development in Meepe",
    cetegory: "Education",
    url: "javascript:;",
    location: "Sri Lanka",
  },
  {
    address: "Saudi Arabia",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Saudi Arabia",
          short_name: "SA",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Saudi Arabia",
      geometry: {
        bounds: {
          Ba: {
            k: 16.379528,
            j: 32.154284,
          },
          ra: {
            j: 34.54899790000002,
            k: 55.66669999999999,
          },
        },
        location: {
          k: 23.885942,
          A: 45.079162,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 16.379528,
            j: 32.154284,
          },
          ra: {
            j: 34.54899790000002,
            k: 55.66669999999999,
          },
        },
      },
      types: ["country", "political"],
    },
    continent: "Asia",
    name: "Regional Centre for Quality and Excellence",
    cetegory: "Education",
    url: "javascript:;",
    location: "Saudi Arabia",
  },
  {
    address: "Sirs El-Layyan, Egypt",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "مستشفى سرس الليان العام",
          short_name: "مستشفى سرس الليان العام",
          types: ["establishment"],
        },
        {
          long_name: "Bor Saeed",
          short_name: "Bor Saeed",
          types: ["route"],
        },
        {
          long_name: "Madinet SERS Al Layanah",
          short_name: "Madinet SERS Al Layanah",
          types: ["administrative_area_level_3", "political"],
        },
        {
          long_name: "Menuf",
          short_name: "Menuf",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Menufia",
          short_name: "Menufia",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Egypt",
          short_name: "EG",
          types: ["country", "political"],
        },
      ],
      formatted_address:
        "مستشفى سرس الليان العام, Bor Saeed, Madinet SERS Al Layanah, Menuf, Menufia, Egypt",
      geometry: {
        bounds: {
          Ba: {
            k: 30.4414036,
            j: 30.4423332,
          },
          ra: {
            j: 30.95981059999997,
            k: 30.961291200000005,
          },
        },
        location: {
          k: 30.442222,
          A: 30.96077600000001,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 30.4405194197085,
            j: 30.4432173802915,
          },
          ra: {
            j: 30.95920191970845,
            k: 30.961899880291526,
          },
        },
      },
      partial_match: true,
      types: ["hospital", "establishment"],
    },
    continent: "Africa",
    name: "Regional Centre for Adult Education",
    cetegory: "Education",
    url: "javascript:;",
    location: "Sirs El-Layyan, Egypt",
  },
  {
    address: "Lodz, Poland",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Lodz",
          short_name: "Lodz",
          types: ["locality", "political"],
        },
        {
          long_name: "Łódź",
          short_name: "Łódź",
          types: ["administrative_area_level_3", "political"],
        },
        {
          long_name: "Łódź",
          short_name: "Łódź",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Łódź Voivodeship",
          short_name: "Łódź Voivodeship",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Poland",
          short_name: "PL",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Lodz, Poland",
      geometry: {
        bounds: {
          Ba: {
            k: 51.6860984,
            j: 51.8601374,
          },
          ra: {
            j: 19.321261700000036,
            k: 19.63992350000001,
          },
        },
        location: {
          k: 51.7592485,
          A: 19.45598330000007,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 51.6860984,
            j: 51.8601374,
          },
          ra: {
            j: 19.321261700000036,
            k: 19.63992350000001,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Europe",
    name: "European Regional Centre for Ecohydrology",
    alias: "erce",
    cetegory: "Natural Sciences",
    url: "http://www.erce.unesco.lodz.pl/",
    location: "Lodz, Poland",
  },
  {
    address:
      "Peters Building University of Dundee Nethergate Dundee United Kingdom",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "University of Dundee",
          short_name: "University of Dundee",
          types: ["establishment"],
        },
        {
          long_name: "Nethergate",
          short_name: "Nethergate",
          types: ["route"],
        },
        {
          long_name: "Dundee",
          short_name: "Dundee",
          types: ["locality", "political"],
        },
        {
          long_name: "Dundee City",
          short_name: "Dundee City",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "United Kingdom",
          short_name: "GB",
          types: ["country", "political"],
        },
        {
          long_name: "DD1 4HN",
          short_name: "DD1 4HN",
          types: ["postal_code"],
        },
        {
          long_name: "Dundee",
          short_name: "Dundee",
          types: ["postal_town"],
        },
      ],
      formatted_address:
        "University of Dundee, Nethergate, Dundee, Dundee City DD1 4HN, UK",
      geometry: {
        bounds: {
          Ba: {
            k: 56.4544704,
            j: 56.45997329999999,
          },
          ra: {
            j: -2.988228700000036,
            k: -2.9743372000000363,
          },
        },
        location: {
          k: 56.45824469999999,
          A: -2.9821428000000196,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 56.4544704,
            j: 56.45997329999999,
          },
          ra: {
            j: -2.988228700000036,
            k: -2.9743372000000363,
          },
        },
      },
      partial_match: true,
      types: ["university", "establishment"],
    },
    continent: "Europe",
    name: "International IHP-HELP Centre for Water Law,Policy and Science",
    alias: "dundee",
    cetegory: "Natural Sciences",
    url: "http://www.dundee.ac.uk/water/",
    location: "Dundee, United Kingdom",
  },
  {
    address: "Minamihara, Tsukuba-shi, Ibaraki-ken 305-8516, Japan",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "305-8516",
          short_name: "305-8516",
          types: ["postal_code"],
        },
        {
          long_name: "Minamihara",
          short_name: "Minamihara",
          types: ["sublocality_level_1", "sublocality", "political"],
        },
        {
          long_name: "Tsukuba",
          short_name: "Tsukuba",
          types: ["locality", "political"],
        },
        {
          long_name: "Ibaraki",
          short_name: "Ibaraki",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Japan",
          short_name: "JP",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Minamihara, Tsukuba, Ibaraki 305-8516, Japan",
      geometry: {
        location: {
          k: 36.1235421,
          A: 140.07313620000002,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 36.1221931197085,
            j: 36.1248910802915,
          },
          ra: {
            j: 140.07178721970854,
            k: 140.0744851802915,
          },
        },
      },
      types: ["postal_code"],
    },
    continent: "Asia",
    name: "International Centre for Water Hazard and Risk Management",
    alias: "icharm",
    cetegory: "Natural Sciences",
    url: "http://www.icharm.pwri.go.jp/",
    location: "Tsukuba, Japan",
  },
  {
    address: "Mojtame Edarat, Daneshjoo Blvd., Yazd, Iran",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Yazd",
          short_name: "Yazd",
          types: ["locality", "political"],
        },
        {
          long_name: "Yazd",
          short_name: "Yazd",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Iran",
          short_name: "IR",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Yazd, Iran",
      geometry: {
        bounds: {
          Ba: {
            k: 31.7937011,
            j: 31.9654157,
          },
          ra: {
            j: 54.22456740000007,
            k: 54.453048699999954,
          },
        },
        location: {
          k: 31.8974232,
          A: 54.35685620000004,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 31.7937011,
            j: 31.9654157,
          },
          ra: {
            j: 54.22456740000007,
            k: 54.453048699999954,
          },
        },
      },
      partial_match: true,
      types: ["locality", "political"],
    },
    continent: "Asia",
    name: "International Centre on Qanats and Historic Hydraulic Structures",
    alias: "icqhs",
    cetegory: "Natural Sciences",
    url: "http://www.icqhs.org/",
    location: "Yazd, Islamic Republic of Iran",
  },
  {
    address:
      "IRTCES, P.O. Box 366, No.20 Chegongzhuang Road West, Beijing, 100044, China",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "100044",
          short_name: "100044",
          types: ["postal_code"],
        },
        {
          long_name: "Haidian",
          short_name: "Haidian",
          types: ["sublocality", "political"],
        },
        {
          long_name: "Beijing",
          short_name: "Beijing",
          types: ["locality", "political"],
        },
        {
          long_name: "Beijing",
          short_name: "Beijing",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "China",
          short_name: "CN",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Haidian, Beijing, China, 100044",
      geometry: {
        bounds: {
          Ba: {
            k: 39.91145540000001,
            j: 39.9875072,
          },
          ra: {
            j: 116.27918360000001,
            k: 116.38840329999994,
          },
        },
        location: {
          k: 39.9321806,
          A: 116.321909,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 39.91145540000001,
            j: 39.9656893,
          },
          ra: {
            j: 116.27918360000001,
            k: 116.38840329999994,
          },
        },
      },
      partial_match: true,
      types: ["postal_code"],
    },
    continent: "Asia",
    name:
      "International Research and Training Centre on Erosion and Sedimentation",
    alias: "irtces",
    cetegory: "Natural Sciences",
    url: "http://en.irtces.org/irtces/index.htm",
    location: "Beijing, China",
  },
  {
    address: "University of Belgrade Bulevar kralja Aleksandra 73",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "School of Electrical Engineering",
          short_name: "ETF",
          types: ["establishment"],
        },
        {
          long_name: "73",
          short_name: "73",
          types: ["street_number"],
        },
        {
          long_name: "Bulevar kralja Aleksandra",
          short_name: "100",
          types: ["route"],
        },
        {
          long_name: "Palilula",
          short_name: "Palilula",
          types: ["neighborhood", "political"],
        },
        {
          long_name: "Belgrade",
          short_name: "Belgrade",
          types: ["locality", "political"],
        },
        {
          long_name: "City of Belgrade",
          short_name: "City of Belgrade",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Serbia",
          short_name: "RS",
          types: ["country", "political"],
        },
      ],
      formatted_address:
        "School of Electrical Engineering, 73 Bulevar kralja Aleksandra, Belgrade, Serbia",
      geometry: {
        bounds: {
          Ba: {
            k: 44.8050036,
            j: 44.8063053,
          },
          ra: {
            j: 20.475125299999945,
            k: 20.47738920000006,
          },
        },
        location: {
          k: 44.8055631,
          A: 20.476230399999963,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 44.8043054697085,
            j: 44.80700343029149,
          },
          ra: {
            j: 20.47490826970852,
            k: 20.477606230291485,
          },
        },
      },
      partial_match: true,
      types: ["establishment"],
    },
    continent: "Europe",
    name: "International Research and Training Centre on Urban Drainage",
    alias: "hikom",
    cetegory: "Natural Sciences",
    url: "https://uia.org/s/or/en/1100058405",
    location: "Belgrade, Serbia",
  },
  {
    address: "Tehran, Islamic Republic of Iran",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Tehran",
          short_name: "Tehran",
          types: ["locality", "political"],
        },
        {
          long_name: "Tehran",
          short_name: "Tehran",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Iran",
          short_name: "IR",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Tehran, Iran",
      geometry: {
        bounds: {
          Ba: {
            k: 35.5683295,
            j: 35.8345498,
          },
          ra: {
            j: 51.093420899999956,
            k: 51.60621630000003,
          },
        },
        location: {
          k: 35.6961111,
          A: 51.4230556,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 35.5683295,
            j: 35.8345498,
          },
          ra: {
            j: 51.093420899999956,
            k: 51.60621630000003,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Asia",
    name: "Regional Centre on Urban Water Management",
    cetegory: "Natural Sciences",
    url: "https://rcuwm.ir/",
    location: "Tehran, Islamic Republic of Iran",
  },
  {
    address: "University Campus of Melendez",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Universidad Catolica",
          short_name: "Universidad Catolica",
          types: ["establishment"],
        },
        {
          long_name: "4C-04",
          short_name: "4C-04",
          types: ["street_number"],
        },
        {
          long_name: "Carrera 94",
          short_name: "Carrera 94",
          types: ["route"],
        },
        {
          long_name: "Melendez",
          short_name: "Melendez",
          types: ["sublocality", "political"],
        },
        {
          long_name: "Cali",
          short_name: "Cali",
          types: ["locality", "political"],
        },
        {
          long_name: "Valle del Cauca",
          short_name: "Valle del Cauca",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Colombia",
          short_name: "CO",
          types: ["country", "political"],
        },
      ],
      formatted_address:
        "Universidad Catolica, Carrera 94 # 4C-04, Cali, Valle del Cauca, Colombia",
      geometry: {
        bounds: {
          Ba: {
            k: 3.3738821,
            j: 3.3761153,
          },
          ra: {
            j: -76.54616299999998,
            k: -76.54319629999998,
          },
        },
        location: {
          k: 3.3750763,
          A: -76.54562120000003,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 3.373649719708498,
            j: 3.376347680291502,
          },
          ra: {
            j: -76.54616299999998,
            k: -76.54319629999998,
          },
        },
      },
      partial_match: true,
      types: ["establishment"],
    },
    continent: "America",
    name:
      "Regional Centre on Urban Water Management for Latin America and the Caribbean",
    alias: "cinara",
    cetegory: "Natural Sciences",
    url: "https://atom.archives.unesco.org/regional-centre-on-urban-water-management-for-latin-america-and-caribbean-proposed",
    location: "Cali, Colombia",
  },
  {
    address: "Tripoli, Libyan Arab Jamahiriya",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Tripoli",
          short_name: "Tripoli",
          types: ["locality", "political"],
        },
        {
          long_name: "Tripoli",
          short_name: "Tripoli",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Libya",
          short_name: "LY",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Tripoli, Libya",
      geometry: {
        bounds: {
          Ba: {
            k: 32.7405047,
            j: 32.9106807,
          },
          ra: {
            j: 12.894859300000007,
            k: 13.330879099999947,
          },
        },
        location: {
          k: 32.8802,
          A: 13.190000000000055,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 32.7405047,
            j: 32.9106807,
          },
          ra: {
            j: 12.894859300000007,
            k: 13.330879099999947,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Africa",
    name: "Regional Centre for Shared Aquifer Resources Management",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Tripoli, Libyan Arab Jamahiriya",
  },
  {
    address: "6th of October City, Egypt",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "6th of October City",
          short_name: "6th of October City",
          types: ["locality", "political"],
        },
        {
          long_name: "Giza",
          short_name: "Giza",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Egypt",
          short_name: "EG",
          types: ["country", "political"],
        },
      ],
      formatted_address: "6th of October City, Giza, Egypt",
      geometry: {
        bounds: {
          Ba: {
            k: 29.8412395,
            j: 30.074442,
          },
          ra: {
            j: 30.73631290000003,
            k: 31.095428399999946,
          },
        },
        location: {
          k: 29.938126,
          A: 30.913980000000038,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 29.8412395,
            j: 30.074442,
          },
          ra: {
            j: 30.73631290000003,
            k: 31.095428399999946,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Africa",
    name:
      "Regional Centre for Training and Water Studies of Arid and Semi-arid Zones",
    alias: "rctws",
    cetegory: "Natural Sciences",
    url: "https://atom.archives.unesco.org/regional-centre-for-training-and-water-studies-in-arid-and-semi-arid-regions-egypt",
    location: "6th of October City, Egypt",
  },
  {
    address: "Kuala Lumpur, Malaysia",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Kuala Lumpur",
          short_name: "Kuala Lumpur",
          types: ["locality", "political"],
        },
        {
          long_name: "Federal Territory of Kuala Lumpur",
          short_name: "Federal Territory of Kuala Lumpur",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Malaysia",
          short_name: "MY",
          types: ["country", "political"],
        },
      ],
      formatted_address:
        "Kuala Lumpur, Federal Territory of Kuala Lumpur, Malaysia",
      geometry: {
        bounds: {
          Ba: {
            k: 3.032754,
            j: 3.245252,
          },
          ra: {
            j: 101.61520199999995,
            k: 101.758509,
          },
        },
        location: {
          k: 3.139003,
          A: 101.68685499999992,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 3.032754,
            j: 3.245252,
          },
          ra: {
            j: 101.61520199999995,
            k: 101.758509,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Asia",
    name:
      "Regional Humid Tropics Hydrology and Water Resources Centre for South-East Asia and the Pacific",
    cetegory: "Natural Sciences",
    url: "http://htc.water.gov.my/",
    location: "Kuala Lumpur, Malaysia",
  },
  {
    address:
      "2013 CAZALAC, Water Center for Arid and Semi-Arid Zones in Latin America and the Caribbean Benavente 980, La Serena, Chile",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "980",
          short_name: "980",
          types: ["street_number"],
        },
        {
          long_name: "Benavente",
          short_name: "Benavente",
          types: ["route"],
        },
        {
          long_name: "La Serena",
          short_name: "La Serena",
          types: ["locality", "political"],
        },
        {
          long_name: "La Serena",
          short_name: "La Serena",
          types: ["administrative_area_level_3", "political"],
        },
        {
          long_name: "Elqui Province",
          short_name: "Elqui Province",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Coquimbo Region",
          short_name: "Coquimbo Region",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Chile",
          short_name: "CL",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Benavente 980, La Serena, Coquimbo Region, Chile",
      geometry: {
        bounds: {
          Ba: {
            k: -29.9087447,
            j: -29.9087431,
          },
          ra: {
            j: -71.24682259999997,
            k: -71.24680430000001,
          },
        },
        location: {
          k: -29.9087447,
          A: -71.24680430000001,
        },
        location_type: "RANGE_INTERPOLATED",
        viewport: {
          Ba: {
            k: -29.9100928802915,
            j: -29.9073949197085,
          },
          ra: {
            j: -71.24816243029153,
            k: -71.24546446970851,
          },
        },
      },
      partial_match: true,
      types: ["street_address"],
    },
    continent: "America",
    name:
      "Regional Water Centre for Arid and Semi-arid Zones of Latin America and the Caribbean",
    alias: "cazalac",
    cetegory: "Natural Sciences",
    url: "https://www.cazalac.org/publico/",
    location: "La Serena, Chile",
  },
  {
    address: "Alexandria, Virginia",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Alexandria",
          short_name: "Alexandria",
          types: ["locality", "political"],
        },
        {
          long_name: "Virginia",
          short_name: "VA",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "United States",
          short_name: "US",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Alexandria, VA, USA",
      geometry: {
        bounds: {
          Ba: {
            k: 38.785216,
            j: 38.845011,
          },
          ra: {
            j: -77.14435900000001,
            k: -77.03728790000002,
          },
        },
        location: {
          k: 38.8048355,
          A: -77.04692139999997,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 38.785216,
            j: 38.845011,
          },
          ra: {
            j: -77.14435900000001,
            k: -77.03728790000002,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "America",
    name: "International Center for Integrated Water Resources Management",
    alias: "iciwarm",
    cetegory: "Natural Sciences",
    url: "https://iciwarm.info/",
    location: "Alexandria, Virginia",
  },
  {
    address:
      "CIMPA  Le Dubellay  4 avenue Joachim - Bat. A  06100 Nice  FRANCE",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "06100",
          short_name: "06100",
          types: ["postal_code"],
        },
        {
          long_name: "Nice",
          short_name: "Nice",
          types: ["locality", "political"],
        },
        {
          long_name: "Alpes-Maritimes",
          short_name: "06",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Provence-Alpes-Côte d'Azur",
          short_name: "PACA",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "France",
          short_name: "FR",
          types: ["country", "political"],
        },
      ],
      formatted_address: "06100 Nice, France",
      geometry: {
        bounds: {
          Ba: {
            k: 43.70899840000001,
            j: 43.7516314,
          },
          ra: {
            j: 7.230703800000015,
            k: 7.285929899999928,
          },
        },
        location: {
          k: 43.7275796,
          A: 7.259891299999936,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 43.70899840000001,
            j: 43.7516314,
          },
          ra: {
            j: 7.230703800000015,
            k: 7.285929899999928,
          },
        },
      },
      partial_match: true,
      types: ["postal_code"],
    },
    continent: "Europe",
    name: "International Centre for Pure and Applied Mathematics",
    alias: "cimpa-icpam",
    cetegory: "Natural Sciences",
    url: "https://www.unwater.org/institution/international-groundwater-resources-assessment-centre-igrac/",
    location: "Nice, France",
  },
  {
    address: "Faridabad, India",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Faridabad",
          short_name: "Faridabad",
          types: ["locality", "political"],
        },
        {
          long_name: "Faridabad",
          short_name: "Faridabad",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Haryana",
          short_name: "HR",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "India",
          short_name: "IN",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Faridabad, Haryana, India",
      geometry: {
        bounds: {
          Ba: {
            k: 28.2912872,
            j: 28.5137834,
          },
          ra: {
            j: 77.24837720000005,
            k: 77.3897713,
          },
        },
        location: {
          k: 28.4089123,
          A: 77.31778940000004,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 28.2912872,
            j: 28.5137834,
          },
          ra: {
            j: 77.24837720000005,
            k: 77.3897713,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Asia",
    name: "Regional Centre for Biotechnology Training and Education",
    alias: "rcb",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Faridabad, India",
  },
  {
    address: "2601CV Delft The Netherlands",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Delft",
          short_name: "Delft",
          types: ["locality", "political"],
        },
        {
          long_name: "Delft",
          short_name: "Delft",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "South Holland",
          short_name: "ZH",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "The Netherlands",
          short_name: "NL",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Delft, The Netherlands",
      geometry: {
        bounds: {
          Ba: {
            k: 51.9665063,
            j: 52.03256349999999,
          },
          ra: {
            j: 4.319509799999992,
            k: 4.407885699999952,
          },
        },
        location: {
          k: 52.01157689999999,
          A: 4.357067700000016,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 51.9665063,
            j: 52.03256349999999,
          },
          ra: {
            j: 4.319509799999992,
            k: 4.407885699999952,
          },
        },
      },
      partial_match: true,
      types: ["locality", "political"],
    },
    continent: "Europe",
    name: "International Groundwater Resources Assessment Centre",
    alias: "un-igrac",
    cetegory: "Natural Sciences",
    url: "https://www.un-igrac.org/",
    location: "Delft, Netherlands",
  },
  {
    address: "Wagga, Australia",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Wagga Wagga",
          short_name: "Wagga Wagga",
          types: ["locality", "political"],
        },
        {
          long_name: "New South Wales",
          short_name: "NSW",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Australia",
          short_name: "AU",
          types: ["country", "political"],
        },
        {
          long_name: "2650",
          short_name: "2650",
          types: ["postal_code"],
        },
      ],
      formatted_address: "Wagga Wagga NSW 2650, Australia",
      geometry: {
        bounds: {
          Ba: {
            k: -35.12595659999999,
            j: -35.087929,
          },
          ra: {
            j: 147.33777910000003,
            k: 147.38270050000006,
          },
        },
        location: {
          k: -35.1155492,
          A: 147.3694772,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: -35.12595659999999,
            j: -35.087929,
          },
          ra: {
            j: 147.33777910000003,
            k: 147.38270050000006,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Oceania",
    name: "International Centre of Water for Food Security",
    cetegory: "Natural Sciences",
    url: "https://www.csiro.au/en/news/news-releases/2016/international-centre-turns-to-csiro-for-food-secure-future",
    location: "Wagga, Australia",
  },
  {
    address: "Pakistan",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Pakistan",
          short_name: "PK",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Pakistan",
      geometry: {
        bounds: {
          Ba: {
            k: 23.6946945,
            j: 37.084107,
          },
          ra: {
            j: 60.872972000000004,
            k: 77.83346940000001,
          },
        },
        location: {
          k: 30.375321,
          A: 69.34511599999996,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 23.6946945,
            j: 37.084107,
          },
          ra: {
            j: 60.872972000000004,
            k: 77.83346940000001,
          },
        },
      },
      types: ["country", "political"],
    },
    continent: "Asia",
    name: "Regional Centre for Water Management Research in Arid Zones",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Pakistan",
  },
  {
    address: "Jalan Tun Ismail 50480 Kuala Lumpur Malaysia",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Jalan Tun Ismail",
          short_name: "Jalan Tun Ismail",
          types: ["route"],
        },
        {
          long_name: "Kuala Lumpur",
          short_name: "Kuala Lumpur",
          types: ["locality", "political"],
        },
        {
          long_name: "Federal Territory of Kuala Lumpur",
          short_name: "Federal Territory of Kuala Lumpur",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Malaysia",
          short_name: "MY",
          types: ["country", "political"],
        },
        {
          long_name: "50480",
          short_name: "50480",
          types: ["postal_code"],
        },
      ],
      formatted_address:
        "Jalan Tun Ismail, 50480 Kuala Lumpur, Federal Territory of Kuala Lumpur, Malaysia",
      geometry: {
        bounds: {
          Ba: {
            k: 3.152098,
            j: 3.166578,
          },
          ra: {
            j: 101.68658400000004,
            k: 101.69194100000004,
          },
        },
        location: {
          k: 3.1588461,
          A: 101.68883010000002,
        },
        location_type: "GEOMETRIC_CENTER",
        viewport: {
          Ba: {
            k: 3.152098,
            j: 3.166578,
          },
          ra: {
            j: 101.68658400000004,
            k: 101.69194100000004,
          },
        },
      },
      types: ["route"],
    },
    continent: "Asia",
    name:
      "International Centre for South-South Cooperation in Science, Technology and Innovation",
    alias: "istic",
    cetegory: "Natural Sciences",
    url: "https://www.istic-unesco.org/v1/",
    location: "Kuala Lumpur, Malaysia",
  },
  {
    address: "Itaipú Binacional, Paraguay and Brazil",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Itaipu Dam",
          short_name: "Itaipu Dam",
          types: ["premise"],
        },
        {
          long_name: "Foz do Iguaçu",
          short_name: "Foz do Iguaçu",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Parana",
          short_name: "PR",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Brazil",
          short_name: "BR",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Itaipu Dam, Brazil",
      geometry: {
        location: {
          k: -25.4080556,
          A: -54.58888890000003,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: -25.4094045802915,
            j: -25.40670661970849,
          },
          ra: {
            j: -54.59023788029151,
            k: -54.58753991970849,
          },
        },
      },
      partial_match: true,
      types: ["premise"],
    },
    continent: "America",
    name:
      "International Centre on Hydroinformatics for Integrated Water Resources Management",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Parque Tecnologico Itaipú Binacional, Paraguay and Brazil",
  },
  {
    address: "Moscow, Russian Federation",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Moscow",
          short_name: "Moscow",
          types: ["locality", "political"],
        },
        {
          long_name: "gorod Moskva",
          short_name: "g. Moskva",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Moscow",
          short_name: "Moscow",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Russia",
          short_name: "RU",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Moscow, Russia",
      geometry: {
        bounds: {
          Ba: {
            k: 55.48992699999999,
            j: 56.009657,
          },
          ra: {
            j: 37.319328799999994,
            k: 37.94566110000005,
          },
        },
        location: {
          k: 55.755826,
          A: 37.6173,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 55.48992699999999,
            j: 56.009657,
          },
          ra: {
            j: 37.319328799999994,
            k: 37.94566110000005,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Europe",
    name: "International Sustainable Energy Development Centre",
    cetegory: "Natural Sciences",
    url: "http://www.isedc-u.com/en/isedc.html",
    location: "Moscow, Russian Federation",
  },
  {
    address: "Guilin, China",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Guilin",
          short_name: "Guilin",
          types: ["locality", "political"],
        },
        {
          long_name: "Guangxi",
          short_name: "Guangxi",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "China",
          short_name: "CN",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Guilin, Guangxi, China",
      geometry: {
        bounds: {
          Ba: {
            k: 24.2588303,
            j: 26.3855658,
          },
          ra: {
            j: 109.61768489999997,
            k: 111.49207409999997,
          },
        },
        location: {
          k: 25.273566,
          A: 110.29019500000004,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 25.2029577,
            j: 25.372462,
          },
          ra: {
            j: 110.1873779,
            k: 110.39543149999997,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Asia",
    name: "International Research Centre on Karst",
    alias: "irck",
    cetegory: "Natural Sciences",
    url: "http://www.irck.edu.cn/",
    location: "Guilin, China",
  },
  {
    address: "Beijing, China",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Beijing",
          short_name: "Beijing",
          types: ["locality", "political"],
        },
        {
          long_name: "Beijing",
          short_name: "Beijing",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "China",
          short_name: "CN",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Beijing, China",
      geometry: {
        bounds: {
          Ba: {
            k: 39.4427581,
            j: 41.0608158,
          },
          ra: {
            j: 115.42341149999993,
            k: 117.51462509999999,
          },
        },
        location: {
          k: 40.070867,
          A: 116.28248699999995,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 39.6612714,
            j: 40.2164962,
          },
          ra: {
            j: 116.01193430000001,
            k: 116.7829835,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Asia",
    name:
      "International Centre on Space Technologies for Cultural and Natural Heritage",
    alias: "hist",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Beijing, China",
  },
  {
    address: "Santo Domingo, Dominican Republic",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Santo Domingo",
          short_name: "Santo Domingo",
          types: ["locality", "political"],
        },
        {
          long_name: "Los Alcarrizos",
          short_name: "Los Alcarrizos",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "National District",
          short_name: "National District",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Dominican Republic",
          short_name: "DO",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Santo Domingo, Dominican Republic",
      geometry: {
        bounds: {
          Ba: {
            k: 18.4130476,
            j: 18.5475279,
          },
          ra: {
            j: -70.01878740000001,
            k: -69.87505320000002,
          },
        },
        location: {
          k: 18.5,
          A: -69.98333330000003,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 18.4130476,
            j: 18.5475279,
          },
          ra: {
            j: -70.01878740000001,
            k: -69.87505320000002,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "America",
    name:
      "Centre for the Sustainable Management of Water Resources in the Caribbean Island Sates",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Santo Domingo, Dominican Republic",
  },
  {
    address: "Rehovot, Israel",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Rehovot",
          short_name: "Rehovot",
          types: ["locality", "political"],
        },
        {
          long_name: "Israel",
          short_name: "IL",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Rehovot, Israel",
      geometry: {
        bounds: {
          Ba: {
            k: 31.86597709999999,
            j: 31.920817,
          },
          ra: {
            j: 34.76197909999996,
            k: 34.85071889999995,
          },
        },
        location: {
          k: 31.892773,
          A: 34.81127200000003,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 31.86597709999999,
            j: 31.920817,
          },
          ra: {
            j: 34.76197909999996,
            k: 34.85071889999995,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Asia",
    name:
      "International Training and Education Centre in Proteomics, Functional Genomics and Bioinformatics",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Rehovot, Israel",
  },
  {
    address: "Federal Republic of Germany",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Germany",
          short_name: "DE",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Germany",
      geometry: {
        bounds: {
          Ba: {
            k: 47.2701115,
            j: 55.058347,
          },
          ra: {
            j: 5.866342499999973,
            k: 15.041896199999996,
          },
        },
        location: {
          k: 51.165691,
          A: 10.451526000000058,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 47.2701115,
            j: 55.058347,
          },
          ra: {
            j: 5.866342499999973,
            k: 15.041896199999996,
          },
        },
      },
      types: ["country", "political"],
    },
    continent: "Europe",
    name: "International Centre on Water Resources and Global Change",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Federal Republic of Germany",
  },
  {
    address: "Faro, Portugal",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Faro",
          short_name: "Faro",
          types: ["locality", "political"],
        },
        {
          long_name: "Faro District",
          short_name: "Faro District",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Portugal",
          short_name: "PT",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Faro, Portugal",
      geometry: {
        bounds: {
          Ba: {
            k: 36.9617104,
            j: 37.0738998,
          },
          ra: {
            j: -8.000400600000035,
            k: -7.809354399999961,
          },
        },
        location: {
          k: 37.0193548,
          A: -7.930439699999965,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 36.9617104,
            j: 37.0738998,
          },
          ra: {
            j: -8.000400600000035,
            k: -7.809354399999961,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Europe",
    name: "International Centre on Coastal Ecohydrology",
    cetegory: "Natural Sciences",
    url: "https://iahs.info/uploads/dms/15057.38-163-165-Chicharo.pdf",
    location: "Faro, Portugal",
  },
  {
    address: "Frutal, Minas Gerais, Brazil",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Frutal",
          short_name: "Frutal",
          types: ["locality", "political"],
        },
        {
          long_name: "Frutal",
          short_name: "Frutal",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Minas Gerais",
          short_name: "MG",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Brazil",
          short_name: "BR",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Frutal - Minas Gerais, Brazil",
      geometry: {
        location: {
          k: -20.0263445,
          A: -48.93595140000002,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: -20.0368275,
            j: -20.0158608,
          },
          ra: {
            j: -48.9519588,
            k: -48.91994399999999,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "America",
    name:
      "HidroEX - International Centre for Education, Capacity Building and Applied Research in Water",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Frutal, Minas Gerais, Brazil",
  },
  {
    address: "Cibinong, Indonesia",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Cibinong",
          short_name: "Cibinong",
          types: ["locality", "political"],
        },
        {
          long_name: "Cibinong",
          short_name: "Cibinong",
          types: ["administrative_area_level_3", "political"],
        },
        {
          long_name: "Bogor",
          short_name: "Bogor",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "West Java",
          short_name: "West Java",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Indonesia",
          short_name: "ID",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Cibinong, Bogor, West Java, Indonesia",
      geometry: {
        bounds: {
          Ba: {
            k: -6.52895,
            j: -6.435957,
          },
          ra: {
            j: 106.79726389999996,
            k: 106.87632989999997,
          },
        },
        location: {
          k: -6.497641499999999,
          A: 106.82822199999998,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: -6.52895,
            j: -6.435957,
          },
          ra: {
            j: 106.79726389999996,
            k: 106.87632989999997,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Asia",
    name: "Asia-Pacific Centre for Ecohydrology",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Cibinong, Indonesia",
  },
  {
    address: "Isfahan, Islamic Republic of Iran",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Isfahan",
          short_name: "Isfahan",
          types: ["locality", "political"],
        },
        {
          long_name: "Isfahan",
          short_name: "Isfahan",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Isfahan",
          short_name: "Isfahan",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Iran",
          short_name: "IR",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Isfahan, Iran",
      geometry: {
        bounds: {
          Ba: {
            k: 32.5045501,
            j: 32.8200277,
          },
          ra: {
            j: 51.52544979999993,
            k: 51.848602400000004,
          },
        },
        location: {
          k: 32.6546275,
          A: 51.66798259999996,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 32.5045501,
            j: 32.8200277,
          },
          ra: {
            j: 51.52544979999993,
            k: 51.848602400000004,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Asia",
    name:
      "Regional Centre for Science Park and Technology Incubator Development in Isfahan",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Isfahan, Islamic Republic of Iran",
  },
  {
    address: "Beijing, China",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Beijing",
          short_name: "Beijing",
          types: ["locality", "political"],
        },
        {
          long_name: "Beijing",
          short_name: "Beijing",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "China",
          short_name: "CN",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Beijing, China",
      geometry: {
        bounds: {
          Ba: {
            k: 39.4427581,
            j: 41.0608158,
          },
          ra: {
            j: 115.42341149999993,
            k: 117.51462509999999,
          },
        },
        location: {
          k: 39.912999,
          A: 116.3045846,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 39.6612714,
            j: 40.2164962,
          },
          ra: {
            j: 116.01193430000001,
            k: 116.7829835,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Asia",
    name:
      "International Research and Training Centre for Science and Technology Strategy",
    alias: "cistrat",
    cetegory: "Natural Sciences",
    url: "http://en.casted.org.cn/channel/index/wid/131",
    location: "Beijing, China",
  },
  {
    address: "Lisbon, Portugal",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Lisbon",
          short_name: "Lisbon",
          types: ["locality", "political"],
        },
        {
          long_name: "Lisbon",
          short_name: "Lisbon",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Portugal",
          short_name: "PT",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Lisbon, Portugal",
      geometry: {
        bounds: {
          Ba: {
            k: 38.6913994,
            j: 38.7958538,
          },
          ra: {
            j: -9.229835600000001,
            k: -9.090571899999986,
          },
        },
        location: {
          k: 38.7222524,
          A: -9.139336599999979,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 38.6913994,
            j: 38.7958538,
          },
          ra: {
            j: -9.229835600000001,
            k: -9.090571899999986,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Europe",
    name:
      "International Centre for Advanced Training of Scientists from Portuguese-Speaking Countries in Areas of Basic Sciences",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Lisbon, Portugal",
  },
  {
    address: "Almaty, Kazakhstan",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Almaty",
          short_name: "Almaty",
          types: ["locality", "political"],
        },
        {
          long_name: "Kazakhstan",
          short_name: "KZ",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Almaty, Kazakhstan",
      geometry: {
        bounds: {
          Ba: {
            k: 43.11050789999999,
            j: 43.4194502,
          },
          ra: {
            j: 76.78567900000007,
            k: 77.14676859999997,
          },
        },
        location: {
          k: 43.2775,
          A: 76.89583330000005,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 43.11050789999999,
            j: 43.4194502,
          },
          ra: {
            j: 76.78567900000007,
            k: 77.14676859999997,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Asia",
    name: "Central Asian Regional Glaciological Centre",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Almaty, Kazakhstan",
  },
  {
    address: "Kaduna, Nigeria",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Kaduna",
          short_name: "Kaduna",
          types: ["locality", "political"],
        },
        {
          long_name: "Kaduna",
          short_name: "Kaduna",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Nigeria",
          short_name: "NG",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Kaduna, Nigeria",
      geometry: {
        bounds: {
          Ba: {
            k: 10.3971566,
            j: 10.6169963,
          },
          ra: {
            j: 7.349789099999953,
            k: 7.5088120000000345,
          },
        },
        location: {
          k: 10.5166667,
          A: 7.433333299999958,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 10.3971566,
            j: 10.6169963,
          },
          ra: {
            j: 7.349789099999953,
            k: 7.5088120000000345,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Africa",
    name: "Regional Centre for Integrated River Basin Management",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Kaduna, Nigeria",
  },
  {
    address: "Nairobi, Kenya",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Nairobi",
          short_name: "Nairobi",
          types: ["locality", "political"],
        },
        {
          long_name: "Nairobi",
          short_name: "Nairobi",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Kenya",
          short_name: "KE",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Nairobi, Kenya",
      geometry: {
        bounds: {
          Ba: {
            k: -1.4448631,
            j: -1.164744,
          },
          ra: {
            j: 36.645419100000026,
            k: 37.049374599999965,
          },
        },
        location: {
          k: -1.2920659,
          A: 36.82194619999996,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: -1.4448631,
            j: -1.164744,
          },
          ra: {
            j: 36.645419100000026,
            k: 37.049374599999965,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Africa",
    name:
      "Regional Centre on Groundwater Resources Education, Training and Research in East Africa",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Nairobi, Kenya",
  },
  {
    address: "Nsukka, Nigeria",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Nsukka",
          short_name: "Nsukka",
          types: ["locality", "political"],
        },
        {
          long_name: "Nsukka",
          short_name: "Nsukka",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Enugu",
          short_name: "Enugu",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Nigeria",
          short_name: "NG",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Nsukka, Nigeria",
      geometry: {
        bounds: {
          Ba: {
            k: 6.7844552,
            j: 6.8874016,
          },
          ra: {
            j: 7.342557800000009,
            k: 7.444181299999968,
          },
        },
        location: {
          k: 6.8566667,
          A: 7.395833300000049,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 6.7844552,
            j: 6.8874016,
          },
          ra: {
            j: 7.342557800000009,
            k: 7.444181299999968,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Africa",
    name: "International Centre for Biotechnology",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Nsukka, Nigeria",
  },
  {
    address: "Marrakech, Morocco",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Marrakesh",
          short_name: "Marrakesh",
          types: ["locality", "political"],
        },
        {
          long_name: "Marrakesh",
          short_name: "Marrakesh",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Marrakesh-Tensift-El Haouz",
          short_name: "Marrakesh-Tensift-El Haouz",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Morocco",
          short_name: "MA",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Marrakesh, Morocco",
      geometry: {
        bounds: {
          Ba: {
            k: 31.5529761,
            j: 31.7162668,
          },
          ra: {
            j: -8.128080400000044,
            k: -7.8876258000000234,
          },
        },
        location: {
          k: 31.6333333,
          A: -8,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 31.5529761,
            j: 31.7162668,
          },
          ra: {
            j: -8.128080400000044,
            k: -7.8876258000000234,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Africa",
    name: "Regional Centre for Renewable Energy and Energy Efficiency",
    alias: "rcreee",
    cetegory: "Natural Sciences",
    url: "http://www.rcreee.org/",
    location: "Marrakech, Morocco",
  },
  {
    address: "Mǎgurele-Bucarest, Romania",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Bucharest",
          short_name: "Bucharest",
          types: ["locality", "political"],
        },
        {
          long_name: "Bucharest",
          short_name: "Bucharest",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Bucharest",
          short_name: "Bucharest",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Romania",
          short_name: "RO",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Bucharest, Romania",
      geometry: {
        bounds: {
          Ba: {
            k: 44.3342445,
            j: 44.541407,
          },
          ra: {
            j: 25.963700099999983,
            k: 26.225574999999935,
          },
        },
        location: {
          k: 44.4325,
          A: 26.103888900000015,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 44.3342445,
            j: 44.541407,
          },
          ra: {
            j: 25.963700099999983,
            k: 26.225574999999935,
          },
        },
      },
      partial_match: true,
      types: ["locality", "political"],
    },
    continent: "Europe",
    name: "International Centre for Advanced Training and Research in Physics",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Mǎgurele-Bucarest, Romania",
  },
  {
    address: "Sudan",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Sudan",
          short_name: "SD",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Sudan",
      geometry: {
        bounds: {
          Ba: {
            k: 9.3472209,
            j: 22.2249183,
          },
          ra: {
            j: 21.81493899999998,
            k: 38.58421920000001,
          },
        },
        location: {
          k: 12.862807,
          A: 30.21763599999997,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 9.3472209,
            j: 22.2249183,
          },
          ra: {
            j: 21.81493899999998,
            k: 38.58421920000001,
          },
        },
      },
      types: ["country", "political"],
    },
    continent: "Africa",
    name:
      "Regional Centre on Capacity Development and Research in Water Harvesting",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Sudan",
  },
  {
    address: "Montevideo, Uruguay",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Montevideo",
          short_name: "Montevideo",
          types: ["locality", "political"],
        },
        {
          long_name: "Montevideo Department",
          short_name: "Montevideo Department",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Uruguay",
          short_name: "UY",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Montevideo, Uruguay",
      geometry: {
        bounds: {
          Ba: {
            k: -34.9378297,
            j: -34.70193,
          },
          ra: {
            j: -56.43136989999999,
            k: -56.02924359999997,
          },
        },
        location: {
          k: -34.8836111,
          A: -56.18194440000002,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: -34.9378297,
            j: -34.70193,
          },
          ra: {
            j: -56.43136989999999,
            k: -56.02924359999997,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "America",
    name:
      "Regional Centre for Groundwater Management for Latin America and the Caribbean",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Montevideo, Uruguay",
  },
  {
    address: "Pietermaritzburg, South Africa",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Pietermaritzburg",
          short_name: "PMB",
          types: ["locality", "political"],
        },
        {
          long_name: "Msunduzi Municipality",
          short_name: "Msunduzi Municipality",
          types: ["administrative_area_level_3", "political"],
        },
        {
          long_name: "Indlovu DC",
          short_name: "Indlovu DC",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "KwaZulu-Natal",
          short_name: "KZN",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "South Africa",
          short_name: "ZA",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Pietermaritzburg, South Africa",
      geometry: {
        bounds: {
          Ba: {
            k: -29.7277701,
            j: -29.5328008,
          },
          ra: {
            j: 30.252068099999974,
            k: 30.449889900000016,
          },
        },
        location: {
          k: -29.6006068,
          A: 30.37941180000007,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: -29.7277701,
            j: -29.5328008,
          },
          ra: {
            j: 30.252068099999974,
            k: 30.449889900000016,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Africa",
    name: "African Centre for Global Change and Water Resources Research",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "South Africa",
  },
  {
    address: "Daejeon, Republic of Korea",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Daejeon",
          short_name: "Daejeon",
          types: ["locality", "political"],
        },
        {
          long_name: "South Korea",
          short_name: "KR",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Daejeon, South Korea",
      geometry: {
        bounds: {
          Ba: {
            k: 36.1833708,
            j: 36.4999477,
          },
          ra: {
            j: 127.24645009999995,
            k: 127.55904370000007,
          },
        },
        location: {
          k: 36.3504119,
          A: 127.38454750000005,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 36.2899,
            j: 36.4563,
          },
          ra: {
            j: 127.31330000000003,
            k: 127.46429999999998,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Asia",
    name: "International Centre for Water Security and Sustainable Management",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Daejeon, Republic of Korea",
  },
  {
    address: "Stockholm, Sweden",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Stockholm",
          short_name: "Stockholm",
          types: ["locality", "political"],
        },
        {
          long_name: "Sweden",
          short_name: "SE",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Stockholm, Sweden",
      geometry: {
        bounds: {
          Ba: {
            k: 59.224443,
            j: 59.42784089999999,
          },
          ra: {
            j: 17.776862100000017,
            k: 18.19822899999997,
          },
        },
        location: {
          k: 59.32893000000001,
          A: 18.064910000000054,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 59.224443,
            j: 59.42784089999999,
          },
          ra: {
            j: 17.776862100000017,
            k: 18.19822899999997,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Europe",
    name: "International Centre for Water Cooperation",
    alias: "watercoo",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Stockholm, Sweden",
  },
  {
    address: "Beijing, China",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Beijing",
          short_name: "Beijing",
          types: ["locality", "political"],
        },
        {
          long_name: "Beijing",
          short_name: "Beijing",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "China",
          short_name: "CN",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Beijing, China",
      geometry: {
        bounds: {
          Ba: {
            k: 39.4427581,
            j: 41.0608158,
          },
          ra: {
            j: 115.42341149999993,
            k: 117.51462509999999,
          },
        },
        location: {
          k: 39.950702,
          A: 116.37696099999994,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 39.6612714,
            j: 40.2164962,
          },
          ra: {
            j: 116.01193430000001,
            k: 116.7829835,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Asia",
    name:
      "International Knowledge Centre for Engineering Sciences and Technology",
    cetegory: "",
    url: "https://www.ikcest.org",
    location: "Beijing, China",
  },
  {
    address: "Aalborg, Denmark",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Aalborg",
          short_name: "Aalborg",
          types: ["locality", "political"],
        },
        {
          long_name: "Aalborg Municipality",
          short_name: "Aalborg Municipality",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "North Denmark Region",
          short_name: "North Denmark Region",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Denmark",
          short_name: "DK",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Aalborg, Denmark",
      geometry: {
        bounds: {
          Ba: {
            k: 56.9745056,
            j: 57.0791535,
          },
          ra: {
            j: 9.749868399999968,
            k: 10.065651900000034,
          },
        },
        location: {
          k: 57.0488195,
          A: 9.921746999999982,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 56.9745056,
            j: 57.0791535,
          },
          ra: {
            j: 9.749868399999968,
            k: 10.065651900000034,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Europe",
    name:
      "Aalborg Centre for Problem-Based Learning in Engineering Science and Sustainability",
    alias: "ucpbl",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Aalborg, Denmark",
  },
  {
    address: "Langfang, China",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Langfang",
          short_name: "Langfang",
          types: ["locality", "political"],
        },
        {
          long_name: "Hebei",
          short_name: "Hebei",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "China",
          short_name: "CN",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Langfang, Hebei, China",
      geometry: {
        bounds: {
          Ba: {
            k: 38.470317,
            j: 40.0850111,
          },
          ra: {
            j: 116.11667690000002,
            k: 117.25998670000001,
          },
        },
        location: {
          k: 39.53804700000001,
          A: 116.68375200000003,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 39.4355307,
            j: 39.6029123,
          },
          ra: {
            j: 116.58416750000004,
            k: 116.84440610000001,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Asia",
    name: "International Centre on Global-scale Geochemistry",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Langfang, China",
  },
  {
    address: "Skopje, Former Yugoslav Republic of Macedonia",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Skopje",
          short_name: "Skopje",
          types: ["locality", "political"],
        },
        {
          long_name: "Skopje",
          short_name: "SK",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Macedonia (FYROM)",
          short_name: "MK",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Skopje, Macedonia (FYROM)",
      geometry: {
        bounds: {
          Ba: {
            k: 41.9450319,
            j: 42.0531486,
          },
          ra: {
            j: 21.312028300000065,
            k: 21.5377522,
          },
        },
        location: {
          k: 41.9973462,
          A: 21.42799560000003,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 41.9450319,
            j: 42.0531486,
          },
          ra: {
            j: 21.312028300000065,
            k: 21.5377522,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Europe",
    name:
      "International Institute of Earthquake Engineering and Engineering Seismology",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Skopje, Former Yugoslav Republic of Macedonia",
  },
  {
    address: "Castellet I la Gornal, Spain",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Castellet i la Gornal",
          short_name: "Castellet i la Gornal",
          types: ["political"],
        },
        {
          long_name: "Barcelona",
          short_name: "B",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Catalonia",
          short_name: "CT",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Spain",
          short_name: "ES",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Castellet i la Gornal, Barcelona, Spain",
      geometry: {
        bounds: {
          Ba: {
            k: 41.2229407,
            j: 41.3144552,
          },
          ra: {
            j: 1.5701556999999866,
            k: 1.6937352999999575,
          },
        },
        location: {
          k: 41.2535361,
          A: 1.5925028999999995,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 41.2229407,
            j: 41.3144552,
          },
          ra: {
            j: 1.5701556999999866,
            k: 1.6937352999999575,
          },
        },
      },
      types: ["political"],
    },
    continent: "Europe",
    name: "International Centre on Mediterranean Biosphere Reserves",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Castellet I la Gornal, Spain",
  },
  {
    address: "Belgrad, Serbia",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Belgrade",
          short_name: "Belgrade",
          types: ["locality", "political"],
        },
        {
          long_name: "City of Belgrade",
          short_name: "City of Belgrade",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Serbia",
          short_name: "RS",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Belgrade, Serbia",
      geometry: {
        bounds: {
          Ba: {
            k: 44.6880454,
            j: 44.9424453,
          },
          ra: {
            j: 20.22171019999996,
            k: 20.618934500000023,
          },
        },
        location: {
          k: 44.8205556,
          A: 20.462222200000042,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 44.6880454,
            j: 44.9424453,
          },
          ra: {
            j: 20.22171019999996,
            k: 20.618934500000023,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Europe",
    name: "Water for Sustainable Development and Adaption to Climate Change",
    cetegory: "Natural Sciences",
    url: "javascript:;",
    location: "Belgrad, Serbia",
  },
  {
    address: "Tehran, Islamic Republic of Iran",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Tehran",
          short_name: "Tehran",
          types: ["locality", "political"],
        },
        {
          long_name: "Tehran",
          short_name: "Tehran",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Iran",
          short_name: "IR",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Tehran, Iran",
      geometry: {
        bounds: {
          Ba: {
            k: 35.5683295,
            j: 35.8345498,
          },
          ra: {
            j: 51.093420899999956,
            k: 51.60621630000003,
          },
        },
        location: {
          k: 35.6961111,
          A: 51.4230556,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 35.5683295,
            j: 35.8345498,
          },
          ra: {
            j: 51.093420899999956,
            k: 51.60621630000003,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Asia",
    name:
      "Regional Educational and Research Centre for Oceanography for Western Asia",
    cetegory: "Intergovernmental Oceanographic Commission",
    url: "javascript:;",
    location: "Tehran, Islamic Republic of Iran",
  },
  {
    address: "Crimea, Yalta, Gurzuf, Leningradskaya Street, 41",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Yalta",
          short_name: "Yalta",
          types: ["locality", "political"],
        },
        {
          long_name: "Crimea",
          short_name: "Crimea",
          types: ["administrative_area_level_1", "political"],
        },
      ],
      formatted_address: "Yalta",
      geometry: {
        bounds: {
          Ba: {
            k: 44.477079,
            j: 44.52635300000001,
          },
          ra: {
            j: 34.11908990000006,
            k: 34.20117189999996,
          },
        },
        location: {
          k: 44.49520500000001,
          A: 34.166300999999976,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 44.477079,
            j: 44.52635300000001,
          },
          ra: {
            j: 34.11908990000006,
            k: 34.20117189999996,
          },
        },
      },
      partial_match: true,
      types: ["locality", "political"],
    },
    continent: "Europe",
    name: "International Children’s Centre",
    cetegory: "Social and Human Sciences",
    url: "http://www.artek.ua/en/",
    location: "Ukraine",
  },
  {
    address: "Byblos, Lebanon",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Byblos",
          short_name: "Byblos",
          types: ["locality", "political"],
        },
        {
          long_name: "Jbeil",
          short_name: "Jbeil",
          types: ["administrative_area_level_3", "political"],
        },
        {
          long_name: "Jbeil",
          short_name: "Jbeil",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Mount Lebanon",
          short_name: "Mount Lebanon",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Lebanon",
          short_name: "LB",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Byblos, Lebanon",
      geometry: {
        location: {
          k: 34.1230022,
          A: 35.65192819999993,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 34.1137647,
            j: 34.13223869999999,
          },
          ra: {
            j: 35.63592080000001,
            k: 35.667935599999964,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Asia",
    name: "International Centre for Human Sciences",
    cetegory: "Social and Human Sciences",
    url: "javascript:;",
    location: "Byblos, Lebanon",
  },
  {
    address: "Greece",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Greece",
          short_name: "GR",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Greece",
      geometry: {
        bounds: {
          Ba: {
            k: 34.8010211,
            j: 41.7488784,
          },
          ra: {
            j: 19.372422700000016,
            k: 29.645147599999973,
          },
        },
        location: {
          k: 39.074208,
          A: 21.824311999999964,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 34.8010211,
            j: 41.7488784,
          },
          ra: {
            j: 19.37358740000002,
            k: 28.24695589999999,
          },
        },
      },
      types: ["country", "political"],
    },
    continent: "Europe",
    name: "Observatory on Women, Sport and Physical Education",
    cetegory: "Social and Human Sciences",
    url: "javascript:;",
    location: "Greece",
  },
  {
    address: "Buenos, Aires, Argentina",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Buenos Aires",
          short_name: "CF",
          types: ["locality", "political"],
        },
        {
          long_name: "Argentina",
          short_name: "AR",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Buenos Aires, Argentina",
      geometry: {
        bounds: {
          Ba: {
            k: -34.7051589,
            j: -34.5265464,
          },
          ra: {
            j: -58.53145219999999,
            k: -58.335144799999966,
          },
        },
        location: {
          k: -34.6037232,
          A: -58.38159310000003,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: -34.7051589,
            j: -34.5265464,
          },
          ra: {
            j: -58.53145219999999,
            k: -58.335144799999966,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "America",
    name: "International Centre for the advancement of Human Rights",
    cetegory: "Social and Human Sciences",
    url: "javascript:;",
    location: "Aires, Argentina",
  },
  {
    address: "Praia, Cape Verde",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Praia",
          short_name: "Praia",
          types: ["locality", "political"],
        },
        {
          long_name: "Praia",
          short_name: "Praia",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Cape Verde",
          short_name: "CV",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Praia, Cape Verde",
      geometry: {
        bounds: {
          Ba: {
            k: 14.9001745,
            j: 14.9727297,
          },
          ra: {
            j: -23.542842899999982,
            k: -23.470616199999995,
          },
        },
        location: {
          k: 14.95,
          A: -23.516666699999973,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 14.9001745,
            j: 14.9727297,
          },
          ra: {
            j: -23.542842899999982,
            k: -23.470616199999995,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Africa",
    name:
      "West Africa Institute for International Research on Regional Integration and Social Transformations",
    cetegory: "Social and Human Sciences",
    url: "javascript:;",
    location: "Praia, Cape Verde",
  },
  {
    address: "Kinshasa, Democratic Republic of the Congo",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Kinshasa",
          short_name: "Kinshasa",
          types: ["locality", "political"],
        },
        {
          long_name: "Mont Amba",
          short_name: "Mont Amba",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Kinshasa",
          short_name: "Kinshasa",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Democratic Republic of the Congo",
          short_name: "CD",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Kinshasa, Democratic Republic of the Congo",
      geometry: {
        bounds: {
          Ba: {
            k: -4.5093664,
            j: -4.2934483,
          },
          ra: {
            j: 15.176498600000059,
            k: 15.46957610000004,
          },
        },
        location: {
          k: -4.3316667,
          A: 15.313888899999938,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: -4.5093664,
            j: -4.2934483,
          },
          ra: {
            j: 15.176498600000059,
            k: 15.46957610000004,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Africa",
    name:
      "Regional Research and Documentation Centre for Women, Gender and Peace-Building in the Great Lakes Region",
    cetegory: "Social and Human Sciences",
    url: "javascript:;",
    location: "Kinshasa, Democratic Republic of the Congo",
  },
  {
    address: "Chungju, Republic of Korea",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Chungju-si",
          short_name: "Chungju-si",
          types: ["locality", "political"],
        },
        {
          long_name: "Chungcheongbuk-do",
          short_name: "Chungcheongbuk-do",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "South Korea",
          short_name: "KR",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Chungju-si, Chungcheongbuk-do, South Korea",
      geometry: {
        bounds: {
          Ba: {
            k: 36.7995968,
            j: 37.2141801,
          },
          ra: {
            j: 127.65090499999997,
            k: 128.12721009999996,
          },
        },
        location: {
          k: 36.9910113,
          A: 127.92594970000005,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 36.952,
            j: 37.0196,
          },
          ra: {
            j: 127.90340000000003,
            k: 127.95129999999995,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Asia",
    name:
      "International Centre of Martial Arts for Youth Development and Engagement",
    cetegory: "Social and Human Sciences",
    url: "javascript:;",
    location: "Chungju, Republic of Korea",
  },
  {
    address: "Ulan Bator, Mongolia",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Ulaanbaatar",
          short_name: "Ulaanbaatar",
          types: ["locality", "political"],
        },
        {
          long_name: "Khan-Uul",
          short_name: "Khan-Uul",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Ulaanbaatar",
          short_name: "Ulaanbaatar",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Mongolia",
          short_name: "MN",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Ulaanbaatar, Mongolia",
      geometry: {
        bounds: {
          Ba: {
            k: 47.8241773,
            j: 47.9591229,
          },
          ra: {
            j: 106.69944269999996,
            k: 107.10429999999997,
          },
        },
        location: {
          k: 47.91999999999999,
          A: 106.92000000000007,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 47.8241773,
            j: 47.9591229,
          },
          ra: {
            j: 106.69944269999996,
            k: 107.10429999999997,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Asia",
    name: "International Institute for the Study of Nomadic Civilizations",
    cetegory: "Culture",
    url: "http://www.nomadic.mn/",
    location: "Ulan Bator, Mongolia",
  },
  {
    address: "Meltzers gate 4, 0257 Oslo, Norway",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "4",
          short_name: "4",
          types: ["street_number"],
        },
        {
          long_name: "Meltzers gate",
          short_name: "Meltzers gate",
          types: ["route"],
        },
        {
          long_name: "Uranienborg",
          short_name: "Uranienborg",
          types: ["sublocality", "political"],
        },
        {
          long_name: "Majorstuen",
          short_name: "Majorstuen",
          types: ["sublocality", "political"],
        },
        {
          long_name: "Oslo",
          short_name: "Oslo",
          types: ["locality", "political"],
        },
        {
          long_name: "Oslo",
          short_name: "Oslo",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Norway",
          short_name: "NO",
          types: ["country", "political"],
        },
        {
          long_name: "0257",
          short_name: "0257",
          types: ["postal_code"],
        },
        {
          long_name: "Oslo",
          short_name: "Oslo",
          types: ["postal_town"],
        },
      ],
      formatted_address: "Meltzers gate 4, 0257 Oslo, Norway",
      geometry: {
        location: {
          k: 59.917624,
          A: 10.720482100000027,
        },
        location_type: "ROOFTOP",
        viewport: {
          Ba: {
            k: 59.91627501970849,
            j: 59.9189729802915,
          },
          ra: {
            j: 10.719133119708431,
            k: 10.721831080291508,
          },
        },
      },
      types: ["street_address"],
    },
    continent: "Europe",
    name: "Nordic World Heritage Foundation",
    cetegory: "Culture",
    url: "https://www.nordicworldheritage.org/",
    location: "Oslo, Norway",
  },
  {
    address: "Calle Maruri S/N 2° piso, Complejo Kusicancha, Cusco, Perú",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Cusco",
          short_name: "Cusco",
          types: ["locality", "political"],
        },
        {
          long_name: "Cusco",
          short_name: "Cusco",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Cusco",
          short_name: "Cusco",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Peru",
          short_name: "PE",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Cusco, Peru",
      geometry: {
        bounds: {
          Ba: {
            k: -13.5626478,
            j: -13.4973908,
          },
          ra: {
            j: -72.02516560000004,
            k: -71.85333249999996,
          },
        },
        location: {
          k: -13.53195,
          A: -71.96746259999998,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: -13.5626478,
            j: -13.4973908,
          },
          ra: {
            j: -72.02516560000004,
            k: -71.85333249999996,
          },
        },
      },
      partial_match: true,
      types: ["locality", "political"],
    },
    continent: "America",
    name:
      "Regional Centre for the Safeguarding of the Intangible Cultural Heritage of Latin America",
    cetegory: "Culture",
    url: "http://www.crespial.org/",
    location: "Cuzco, Peru",
  },
  {
    address: "Bogotá, Colombia",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Bogota",
          short_name: "Bogota",
          types: ["locality", "political"],
        },
        {
          long_name: "Bogota",
          short_name: "Bogotá, D.C.",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Colombia",
          short_name: "CO",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Bogotá, Bogota, Colombia",
      geometry: {
        bounds: {
          Ba: {
            k: 3.72977,
            j: 4.8371,
          },
          ra: {
            j: -74.45177000000001,
            k: -73.99631,
          },
        },
        location: {
          k: 4.598055599999999,
          A: -74.0758333,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 3.72977,
            j: 4.8371,
          },
          ra: {
            j: -74.45177000000001,
            k: -73.99631,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "America",
    name:
      "Regional Centre for Book Development in Latin America and the Caribbean",
    cetegory: "Culture",
    url: "https://www.devex.com/organizations/regional-center-for-book-development-in-latin-america-and-the-caribbean-cerlalc-141016",
    location: "Bogotá, Colombia",
  },
  {
    address: "Zadar, Croatia",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Zadar",
          short_name: "Zadar",
          types: ["locality", "political"],
        },
        {
          long_name: "Općina Zadar",
          short_name: "Općina Zadar",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Zadar County",
          short_name: "Zadar County",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Croatia",
          short_name: "HR",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Zadar, Croatia",
      geometry: {
        bounds: {
          Ba: {
            k: 44.0829053,
            j: 44.187006,
          },
          ra: {
            j: 15.195590300000049,
            k: 15.302237699999978,
          },
        },
        location: {
          k: 44.119371,
          A: 15.231364799999938,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 44.0829053,
            j: 44.187006,
          },
          ra: {
            j: 15.195590300000049,
            k: 15.302237699999978,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Europe",
    name: "International Centre for Underwater Archaeology",
    cetegory: "Culture",
    url: "https://icua.hr/en",
    location: "Zadar, Croatia",
  },
  {
    address:
      "Tongji University No.1239 Siping Road, Shanghai 200092, P.R. China",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Tongji University （Southeast Gate）",
          short_name: "Tongji University （Southeast Gate）",
          types: ["point_of_interest", "establishment"],
        },
        {
          long_name: "1239号",
          short_name: "1239号",
          types: ["street_number"],
        },
        {
          long_name: "No.441, Siping Road",
          short_name: "No.441, Siping Rd",
          types: ["route"],
        },
        {
          long_name: "Yangpu",
          short_name: "Yangpu",
          types: ["sublocality", "political"],
        },
        {
          long_name: "Shanghai",
          short_name: "Shanghai",
          types: ["locality", "political"],
        },
        {
          long_name: "Shanghai",
          short_name: "Shanghai",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "China",
          short_name: "CN",
          types: ["country", "political"],
        },
        {
          long_name: "200092",
          short_name: "200092",
          types: ["postal_code"],
        },
      ],
      formatted_address:
        "Tongji University （Southeast Gate）, 1239 No.441, Siping Road, Yangpu, China, 200092",
      geometry: {
        location: {
          k: 31.28269,
          A: 121.50626999999997,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 31.2813410197085,
            j: 31.2840389802915,
          },
          ra: {
            j: 121.50492101970849,
            k: 121.50761898029145,
          },
        },
      },
      partial_match: true,
      types: ["university", "point_of_interest", "establishment"],
    },
    continent: "Asia",
    name:
      "World Heritage Institute of Training and Research Institute for the Asia and the Pacific Region",
    cetegory: "Culture",
    url: "http://www.whitr-ap.org/",
    location: "Shanghai, China",
  },
  {
    address: "Midrand 1685 South Africa",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "1685",
          short_name: "1685",
          types: ["postal_code"],
        },
        {
          long_name: "Gauteng",
          short_name: "GP",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "South Africa",
          short_name: "ZA",
          types: ["country", "political"],
        },
      ],
      formatted_address: "1685, South Africa",
      geometry: {
        bounds: {
          Ba: {
            k: -26.056059,
            j: -25.931169,
          },
          ra: {
            j: 28.06558199999995,
            k: 28.182724000000007,
          },
        },
        location: {
          k: -26.0142774,
          A: 28.14737709999997,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: -26.056059,
            j: -25.931169,
          },
          ra: {
            j: 28.06558199999995,
            k: 28.182724000000007,
          },
        },
      },
      types: ["postal_code"],
    },
    continent: "Africa",
    name: "African World Heritage Fund",
    cetegory: "Culture",
    url: "https://awhf.net/",
    location: "South Africa",
  },
  {
    address:
      "Olusegun Obasanjo Presidential Library, Oke-Mosan, Abeokuta, Nigeria",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Oke Mosan",
          short_name: "Oke Mosan",
          types: ["sublocality", "political"],
        },
        {
          long_name: "Abeokuta",
          short_name: "Abeokuta",
          types: ["locality", "political"],
        },
        {
          long_name: "Ogun State",
          short_name: "Ogun State",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Nigeria",
          short_name: "NG",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Oke Mosan, Abeokuta, Nigeria",
      geometry: {
        bounds: {
          Ba: {
            k: 7.1063438,
            j: 7.1314685,
          },
          ra: {
            j: 3.361644599999977,
            k: 3.397865300000035,
          },
        },
        location: {
          k: 7.1183194,
          A: 3.3741646999999375,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 7.1063438,
            j: 7.1314685,
          },
          ra: {
            j: 3.361644599999977,
            k: 3.397865300000035,
          },
        },
      },
      partial_match: true,
      types: ["sublocality", "political"],
    },
    continent: "Africa",
    name:
      "Institute for African culture and International Understanding Abeokuta",
    cetegory: "Culture",
    url: "https://oopl.org.ng/iaciu/",
    location: "Ogun State, Nigeria",
  },
  {
    address: "Beijing China",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "China",
          short_name: "CN",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Beijing China",
      geometry: {
        bounds: {
          Ba: {
            k: 18.1535216,
            j: 53.56097399999999,
          },
          ra: {
            j: 73.49941369999999,
            k: 134.77280999999994,
          },
        },
        location: {
          k: 39.92147,
          A: 116.44310799999994,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 18.1539677,
            j: 53.56097399999999,
          },
          ra: {
            j: 73.49941369999999,
            k: 134.77280999999994,
          },
        },
      },
      types: ["country", "political"],
    },
    continent: "Asia",
    name:
      "International Training Centre for Intangible Cultural Heritage in the Asia and the Pacific Region",
    cetegory: "Culture",
    url: "javascript:;",
    location: "Beijing China",
  },
  {
    address: "Republic of Korea",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "South Korea",
          short_name: "KR",
          types: ["country", "political"],
        },
      ],
      formatted_address: "South Korea",
      geometry: {
        bounds: {
          Ba: {
            k: 33.1061096,
            j: 38.6169312,
          },
          ra: {
            j: 124.60813899999994,
            k: 130.92321779999997,
          },
        },
        location: {
          k: 35.907757,
          A: 127.76692200000002,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 33.1061096,
            j: 38.6169312,
          },
          ra: {
            j: 124.60813899999994,
            k: 129.58467099999996,
          },
        },
      },
      types: ["country", "political"],
    },
    continent: "Asia",
    name:
      "International Information and Networking Centre for Intangible Cultural Heritage in the Asia-Pacific Region",
    cetegory: "Culture",
    url: "javascript:;",
    location: "Republic of Korea",
  },
  {
    address: "Japan",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Japan",
          short_name: "JP",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Japan",
      geometry: {
        bounds: {
          Ba: {
            k: 24.0460446,
            j: 45.5227719,
          },
          ra: {
            j: 122.93383019999999,
            k: 153.98743049999996,
          },
        },
        location: {
          k: 36.204824,
          A: 138.252924,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 33.4332424,
            j: 41.5468212,
          },
          ra: {
            j: 130.8604967,
            k: 142.07230490000006,
          },
        },
      },
      types: ["country", "political"],
    },
    continent: "Asia",
    name:
      "International Research Centre for Intangible Cultural Heritage in the Asia-Pacific Region",
    cetegory: "Culture",
    url: "javascript:;",
    location: "Japan",
  },
  {
    address: "Manama, Royaume de Bahre?n",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Manama",
          short_name: "Manama",
          types: ["locality", "political"],
        },
        {
          long_name: "Bahrain",
          short_name: "BH",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Manama, Bahrain",
      geometry: {
        bounds: {
          Ba: {
            k: 26.1937839,
            j: 26.2589938,
          },
          ra: {
            j: 50.55113129999995,
            k: 50.62628510000002,
          },
        },
        location: {
          k: 26.2166667,
          A: 50.58333330000005,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 26.1937839,
            j: 26.2589938,
          },
          ra: {
            j: 50.55113129999995,
            k: 50.62628510000002,
          },
        },
      },
      partial_match: true,
      types: ["locality", "political"],
    },
    continent: "Asia",
    name: "Arab Regional Centre for World Heritage",
    cetegory: "Culture",
    url: "https://www.arcwh.org/",
    location: "Bahrain",
  },
  {
    address: "Brazil",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Brazil",
          short_name: "BR",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Brazil",
      geometry: {
        bounds: {
          Ba: {
            k: -33.7517484,
            j: 5.271602,
          },
          ra: {
            j: -73.98281700000001,
            k: -29.345023500000025,
          },
        },
        location: {
          k: -14.235004,
          A: -51.92527999999999,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: -33.7517484,
            j: 5.271602,
          },
          ra: {
            j: -73.98281700000001,
            k: -32.37818649999997,
          },
        },
      },
      types: ["country", "political"],
    },
    continent: "America",
    name: "Regional Heritage Management Training Centre",
    cetegory: "Culture",
    url: "javascript:;",
    location: "Brazil",
  },
  {
    address: "Tehran, Islamic Republic of Iran",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Tehran",
          short_name: "Tehran",
          types: ["locality", "political"],
        },
        {
          long_name: "Tehran",
          short_name: "Tehran",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Iran",
          short_name: "IR",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Tehran, Iran",
      geometry: {
        bounds: {
          Ba: {
            k: 35.5683295,
            j: 35.8345498,
          },
          ra: {
            j: 51.093420899999956,
            k: 51.60621630000003,
          },
        },
        location: {
          k: 35.6961111,
          A: 51.4230556,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 35.5683295,
            j: 35.8345498,
          },
          ra: {
            j: 51.093420899999956,
            k: 51.60621630000003,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Asia",
    name:
      "Regional Research centre for Safeguarding Intangible Cultural Heritage in West and Central Asia",
    cetegory: "Culture",
    url: "javascript:;",
    location: "Tehran, Islamic Republic of Iran",
  },
  {
    address: "Sofia, Bulgaria",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Sofia",
          short_name: "Sofia",
          types: ["locality", "political"],
        },
        {
          long_name: "Sofia",
          short_name: "Sofia",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Sofia-city",
          short_name: "Sofia-city",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Bulgaria",
          short_name: "BG",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Sofia, Bulgaria",
      geometry: {
        bounds: {
          Ba: {
            k: 42.4900111,
            j: 42.7877752,
          },
          ra: {
            j: 23.190988500000003,
            k: 23.45690490000004,
          },
        },
        location: {
          k: 42.6977082,
          A: 23.321867500000053,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 42.6030891,
            j: 42.7877752,
          },
          ra: {
            j: 23.190988500000003,
            k: 23.45690490000004,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Europe",
    name:
      "Regional Centre for the Safeguarding of Intangible Cultural Heritage",
    cetegory: "Culture",
    url: "javascript:;",
    location: "Sofia, Bulgaria",
  },
  {
    address: "Zacatecas, Mexico",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Zacatecas",
          short_name: "Zacatecas",
          types: ["locality", "political"],
        },
        {
          long_name: "Zacatecas",
          short_name: "ZAC",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Mexico",
          short_name: "MX",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Zacatecas, Mexico",
      geometry: {
        bounds: {
          Ba: {
            k: 22.7451586,
            j: 22.7901168,
          },
          ra: {
            j: -102.61969429999999,
            k: -102.5549838,
          },
        },
        location: {
          k: 22.7709249,
          A: -102.58325389999999,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 22.7451586,
            j: 22.7901168,
          },
          ra: {
            j: -102.61969429999999,
            k: -102.5549838,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "America",
    name: "Regional World Heritage Institute",
    cetegory: "Culture",
    url: "javascript:;",
    location: "Zacatecas, Mexico",
  },
  {
    address: "Bobo-Dioulasso, Burkina Faso",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Bobo-Dioulasso",
          short_name: "Bobo-Dioulasso",
          types: ["locality", "political"],
        },
        {
          long_name: "Houet",
          short_name: "Houet",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Hauts-Bassins",
          short_name: "Hauts-Bassins",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Burkina Faso",
          short_name: "BF",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Bobo-Dioulasso, Burkina Faso",
      geometry: {
        bounds: {
          Ba: {
            k: 11.119617,
            j: 11.2281612,
          },
          ra: {
            j: -4.35846760000004,
            k: -4.22698969999999,
          },
        },
        location: {
          k: 11.1833333,
          A: -4.283333299999981,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 11.119617,
            j: 11.2281612,
          },
          ra: {
            j: -4.35846760000004,
            k: -4.22698969999999,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Africa",
    name: "Regional Centre for the Living Arts in Africa",
    cetegory: "Culture",
    url: "javascript:;",
    location: "Bobo-Dioulasso, Burkina Faso",
  },
  {
    address: "Reykjavik, Iceland",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Reykjavik",
          short_name: "Reykjavik",
          types: ["locality", "political"],
        },
        {
          long_name: "Capital Region",
          short_name: "Capital Region",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Iceland",
          short_name: "IS",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Reykjavik, Iceland",
      geometry: {
        bounds: {
          Ba: {
            k: 64.0898138,
            j: 64.1750702,
          },
          ra: {
            j: -21.986502100000052,
            k: -21.72730409999997,
          },
        },
        location: {
          k: 64.1333333,
          A: -21.93333329999996,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 64.0898138,
            j: 64.1750702,
          },
          ra: {
            j: -21.986502100000052,
            k: -21.72730409999997,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Europe",
    name:
      "Vigdis International Centre for Multilingualism and Intercultural Understanding",
    cetegory: "Culture",
    url: "javascript:;",
    location: "Reykjavik, Iceland",
  },
  {
    address: "Amman, Jordan",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Amman",
          short_name: "Amman",
          types: ["locality", "political"],
        },
        {
          long_name: "Amman",
          short_name: "Amman",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Jordan",
          short_name: "JO",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Amman, Jordan",
      geometry: {
        location: {
          k: 31.9565783,
          A: 35.945695099999966,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 31.9471108,
            j: 31.9660448,
          },
          ra: {
            j: 35.92968769999993,
            k: 35.9617025,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Asia",
    name: "International Museum for Women in the Arts",
    cetegory: "Culture",
    url: "javascript:;",
    location: "Amman, Jordan",
  },
  {
    address: "Doha, Qatar",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Doha",
          short_name: "Doha",
          types: ["locality", "political"],
        },
        {
          long_name: "Doha",
          short_name: "Doha",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Qatar",
          short_name: "QA",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Doha, Qatar",
      geometry: {
        bounds: {
          Ba: {
            k: 25.1954283,
            j: 25.4125783,
          },
          ra: {
            j: 51.43079639999996,
            k: 51.62812120000001,
          },
        },
        location: {
          k: 25.2866667,
          A: 51.53333329999998,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 25.1954283,
            j: 25.4125783,
          },
          ra: {
            j: 51.43079639999996,
            k: 51.62812120000001,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Asia",
    name: "Regional Centre for Contemporary Art",
    cetegory: "Culture",
    url: "javascript:;",
    location: "Doha, Qatar",
  },
  {
    address: "Spain",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Spain",
          short_name: "ES",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Spain",
      geometry: {
        bounds: {
          Ba: {
            k: 27.6377894,
            j: 43.7923795,
          },
          ra: {
            j: -18.160788000000025,
            k: 4.327783899999986,
          },
        },
        location: {
          k: 40.46366700000001,
          A: -3.7492200000000366,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 35.17300000000001,
            j: 45.244,
          },
          ra: {
            j: -12.524000000000001,
            k: 5.097999999999956,
          },
        },
      },
      types: ["country", "political"],
    },
    continent: "Europe",
    name: "International Center for Rock Art and the World Heritage Convention",
    cetegory: "Culture",
    url: "javascript:;",
    location: "Spain",
  },
  {
    address: "Turin, Italy",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Turin",
          short_name: "Turin",
          types: ["locality", "political"],
        },
        {
          long_name: "Turin",
          short_name: "Turin",
          types: ["administrative_area_level_3", "political"],
        },
        {
          long_name: "Turin",
          short_name: "TO",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Piedmont",
          short_name: "Piedmont",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Italy",
          short_name: "IT",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Turin, Italy",
      geometry: {
        bounds: {
          Ba: {
            k: 45.00677659999999,
            j: 45.1335014,
          },
          ra: {
            j: 7.577850200000057,
            k: 7.762328199999956,
          },
        },
        location: {
          k: 45.070312,
          A: 7.686856499999976,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 45.00677659999999,
            j: 45.1335014,
          },
          ra: {
            j: 7.577850200000057,
            k: 7.762328199999956,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Europe",
    name:
      "International Centre on the Economics of Culture and World Heritage Studies",
    cetegory: "Culture",
    url: "javascript:;",
    location: "Turin, Italy",
  },
  {
    address: "Dehradun, India",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Dehradun",
          short_name: "Dehradun",
          types: ["locality", "political"],
        },
        {
          long_name: "Dehradun",
          short_name: "Dehradun",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Uttarakhand",
          short_name: "UT",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "India",
          short_name: "IN",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Dehradun, Uttarakhand, India",
      geometry: {
        bounds: {
          Ba: {
            k: 30.2466633,
            j: 30.4041936,
          },
          ra: {
            j: 77.92533879999996,
            k: 78.10893050000004,
          },
        },
        location: {
          k: 30.3164945,
          A: 78.03219179999996,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 30.2466633,
            j: 30.4041936,
          },
          ra: {
            j: 77.92533879999996,
            k: 78.10893050000004,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Asia",
    name:
      "Centre on World Natural Heritage Management and Training for the Asia and Pacific region",
    cetegory: "Culture",
    url: "javascript:;",
    location: "Dehradun, India",
  },
  {
    address: "Algiers, Algeria",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Algiers",
          short_name: "Algiers",
          types: ["locality", "political"],
        },
        {
          long_name: "Algiers",
          short_name: "Algiers",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Algeria",
          short_name: "DZ",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Algiers, Algeria",
      geometry: {
        location: {
          k: 36.752887,
          A: 3.0420480000000225,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 36.7439464,
            j: 36.7618265,
          },
          ra: {
            j: 3.0260405999999875,
            k: 3.0580554000000575,
          },
        },
      },
      types: ["locality", "political"],
    },
    continent: "Africa",
    name:
      "Regional Centre for safeguarding intangible cultural heritage in Africa",
    cetegory: "Culture",
    url: "javascript:;",
    location: "Algiers, Algeria",
  },
  {
    address: "45 rue de Turbigo, 75003 Paris, France",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "45",
          short_name: "45",
          types: ["street_number"],
        },
        {
          long_name: "Rue de Turbigo",
          short_name: "Rue de Turbigo",
          types: ["route"],
        },
        {
          long_name: "Paris",
          short_name: "Paris",
          types: ["locality", "political"],
        },
        {
          long_name: "Paris",
          short_name: "75",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Île-de-France",
          short_name: "IDF",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "France",
          short_name: "FR",
          types: ["country", "political"],
        },
        {
          long_name: "75003",
          short_name: "75003",
          types: ["postal_code"],
        },
      ],
      formatted_address: "45 Rue de Turbigo, 75003 Paris, France",
      geometry: {
        location: {
          k: 48.8651108,
          A: 2.3536968999999317,
        },
        location_type: "ROOFTOP",
        viewport: {
          Ba: {
            k: 48.8637618197085,
            j: 48.8664597802915,
          },
          ra: {
            j: 2.3523479197085635,
            k: 2.3550458802915273,
          },
        },
      },
      types: ["street_address"],
    },
    continent: "Europe",
    name:
      "ISSN International Centre for the Registration of Serial Publications",
    cetegory: "Communication and Information",
    url: "javascript:;",
    location: "Paris, France",
  },
  {
    address: "Al Qudaibiya P.O. Box 43 Manama, Kingdom of Bahrain",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Qudaibiya",
          short_name: "Qudaibiya",
          types: ["sublocality", "political"],
        },
        {
          long_name: "Manama",
          short_name: "Manama",
          types: ["locality", "political"],
        },
        {
          long_name: "Capital Governorate",
          short_name: "Capital Governorate",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Bahrain",
          short_name: "BH",
          types: ["country", "political"],
        },
      ],
      formatted_address: "Qudaibiya, Manama, Bahrain",
      geometry: {
        bounds: {
          Ba: {
            k: 26.2077109,
            j: 26.2297388,
          },
          ra: {
            j: 50.58227850000003,
            k: 50.59774059999995,
          },
        },
        location: {
          k: 26.2186782,
          A: 50.59382149999999,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 26.2077109,
            j: 26.2297388,
          },
          ra: {
            j: 50.58227850000003,
            k: 50.59774059999995,
          },
        },
      },
      partial_match: true,
      types: ["sublocality", "political"],
    },
    continent: "Asia",
    name: "Regional Centre for Information and Communication Technology",
    cetegory: "Communication and Information",
    url: "http://www.regcict.com/",
    location: "Manama, Bahrain",
  },
  {
    address: "Av. Nações Unidas, 11.541 São Paulo, SP Brazil",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "11541",
          short_name: "11541",
          types: ["street_number"],
        },
        {
          long_name: "Avenida das Nações Unidas",
          short_name: "Av. das Nações Unidas",
          types: ["route"],
        },
        {
          long_name: "Cidade Monções",
          short_name: "Cidade Monções",
          types: ["neighborhood", "political"],
        },
        {
          long_name: "São Paulo",
          short_name: "São Paulo",
          types: ["locality", "political"],
        },
        {
          long_name: "São Paulo",
          short_name: "São Paulo",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "São Paulo",
          short_name: "SP",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "Brazil",
          short_name: "BR",
          types: ["country", "political"],
        },
        {
          long_name: "04571",
          short_name: "04571",
          types: ["postal_code_prefix", "postal_code"],
        },
      ],
      formatted_address:
        "Avenida das Nações Unidas, 11541 - Cidade Monções, São Paulo, Brazil",
      geometry: {
        location: {
          k: -23.6013838,
          A: -46.69508080000003,
        },
        location_type: "ROOFTOP",
        viewport: {
          Ba: {
            k: -23.6027327802915,
            j: -23.6000348197085,
          },
          ra: {
            j: -46.69642978029151,
            k: -46.69373181970849,
          },
        },
      },
      partial_match: true,
      types: ["street_address"],
    },
    continent: "America",
    name:
      "Regional Centre of Studies for the Development of the Information Society",
    cetegory: "Communication and Information",
    url: "javascript:;",
    location: "Sao Paulo, Brazil",
  },
  {
    address: "Kotnikova ulica 38 - 1 000  Ljubljana – Slovenia",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "38",
          short_name: "38",
          types: ["street_number"],
        },
        {
          long_name: "Kotnikova ulica",
          short_name: "Kotnikova ulica",
          types: ["route"],
        },
        {
          long_name: "Ljubljana",
          short_name: "Ljubljana",
          types: ["locality", "political"],
        },
        {
          long_name: "Slovenia",
          short_name: "SI",
          types: ["country", "political"],
        },
        {
          long_name: "1000",
          short_name: "1000",
          types: ["postal_code"],
        },
        {
          long_name: "Ljubljana",
          short_name: "Ljubljana",
          types: ["postal_town"],
        },
      ],
      formatted_address: "Kotnikova ulica 38, 1000 Ljubljana, Slovenia",
      geometry: {
        location: {
          k: 46.0573371,
          A: 14.514770799999951,
        },
        location_type: "ROOFTOP",
        viewport: {
          Ba: {
            k: 46.0559881197085,
            j: 46.0586860802915,
          },
          ra: {
            j: 14.51342181970847,
            k: 14.516119780291547,
          },
        },
      },
      partial_match: true,
      types: ["street_address"],
    },
    continent: "Europe",
    name:
      "IZUM-Regional Centre for Library Information Systems and Current Research Information Systems",
    cetegory: "Communication and Information",
    url: "javascript:;",
    location: "Maribor, Slovenia",
  },
  {
    address: "6202 University of Oregon Eugene, OR 97403-6202",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "Oregon University System",
          short_name: "Oregon University System",
          types: ["point_of_interest", "establishment"],
        },
        {
          long_name: "University of Oregon",
          short_name: "University of Oregon",
          types: ["establishment"],
        },
        {
          long_name: "1431",
          short_name: "1431",
          types: ["street_number"],
        },
        {
          long_name: "Johnson Lane",
          short_name: "Johnson Lane",
          types: ["route"],
        },
        {
          long_name: "Eugene",
          short_name: "Eugene",
          types: ["locality", "political"],
        },
        {
          long_name: "Lane County",
          short_name: "Lane County",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Oregon",
          short_name: "OR",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "United States",
          short_name: "US",
          types: ["country", "political"],
        },
        {
          long_name: "97403",
          short_name: "97403",
          types: ["postal_code"],
        },
      ],
      formatted_address:
        "Oregon University System, University of Oregon, 1431 Johnson Lane, Eugene, OR 97403, USA",
      geometry: {
        location: {
          k: 44.0447928,
          A: -123.07267680000001,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 44.0434438197085,
            j: 44.04614178029149,
          },
          ra: {
            j: -123.07402578029149,
            k: -123.07132781970847,
          },
        },
      },
      partial_match: true,
      types: ["university", "point_of_interest", "establishment"],
    },
    continent: "America",
    name:
      "Institute for Intercultural Dialogue and Conflict-Sensitive Reporting",
    cetegory: "Communication and Information",
    url: "javascript:;",
    location: "Oregon, United States of America",
  },
  {
    address: "685 Cottage Club Road, Unit 13, Stowe, VT 05672",
    lonlat: [],
    desc: "",
    addr: {
      address_components: [
        {
          long_name: "13",
          short_name: "13",
          types: ["subpremise"],
        },
        {
          long_name: "685",
          short_name: "685",
          types: ["street_number"],
        },
        {
          long_name: "Cottage Club Road",
          short_name: "Cottage Club Rd",
          types: ["route"],
        },
        {
          long_name: "Stowe",
          short_name: "Stowe",
          types: ["locality", "political"],
        },
        {
          long_name: "Lamoille County",
          short_name: "Lamoille County",
          types: ["administrative_area_level_2", "political"],
        },
        {
          long_name: "Vermont",
          short_name: "VT",
          types: ["administrative_area_level_1", "political"],
        },
        {
          long_name: "United States",
          short_name: "US",
          types: ["country", "political"],
        },
        {
          long_name: "05672",
          short_name: "05672",
          types: ["postal_code"],
        },
      ],
      formatted_address: "685 Cottage Club Road #13, Stowe, VT 05672, USA",
      geometry: {
        location: {
          k: 44.483823,
          A: -72.714992,
        },
        location_type: "APPROXIMATE",
        viewport: {
          Ba: {
            k: 44.4824740197085,
            j: 44.4851719802915,
          },
          ra: {
            j: -72.71634098029153,
            k: -72.71364301970851,
          },
        },
      },
      partial_match: true,
      types: ["subpremise"],
    },
    continent: "America",
    name: "International Institute for Peace",
    cetegory: "Communication and Information",
    url: "https://www.iipvienna.com/",
    location: "Rutgers, the State University of New Jersey",
  },
];

export{
  addressCallback
}
