'use strict';

exports.__esModule = true;
exports.default = {
  All:'全部',
  all:'全部',
  Home: '首页',
  Countries: '国家指数',
  Information: '信息',
  CountriesAlong: '国家',
  AcademicResearch: '学术研究',
  DataCenter: '数据中心',
  More: '更多',
  TheBeltandRoadIndex: '一带一路',
  PleaseSelect: '请选择',
  Login:'登录',
  Registerforfree:'注册',
  Logout:'退出',
  IndexRanking: '指数排行',
  Comparison: '比较',

  signin:'登录',
  signup:'注册',
  Chairs:'教席',
  Chair:'教席',
  Projects:'项目',
  Category2Centres:'联合国教科文组织二类中心',
  UNESCO_chairs:'教科文组织教席',
  ProgrammeProjects:'计划项目',
  More:'更多',
  Chairs_Location_Map:'主席位置地图',
  News:' 新闻',
  UNESCOCategory2Centres:'联合国教科文组织二类中心',
  Unitwin_Network:'结对大学',
  UNESCO_Link:'联合国教科文组织链接',
  Detail:'详情',
  Location:'地址',
  Publications:'期刊',
  Updates_and_News:'最新动态与新闻',
  Showing:'展示',
  Agreement_Date:'签署日期',
  Description:'描述',
  Contact:'联系方式',
  Objective:'目标',
  Vision:'展望',
  Updates:' 最新动态',
  Website:'网站',
  Estableshed:'创立年份',
  Year:'年份 ',
  Search:'搜索',
  Country:'国家',

  Reports:'报告',
  Events:'事件',
  Centres:'中心',
  UNESCO_Centres:'联合国教科文组织中心',
  Asia:'亚洲',
  Europe:'欧洲',
  America:'美洲',
  Africa:'非洲',
  Oceania:'大洋洲',
  Event_Map:'事件图',
  UNESCO_Links:'联合国教科文组织链接',
  Trending_News:'时事新闻',
  Latest_News:'最新资讯',
  Her_Tracks:'个人轨迹',
  Person_Profile:'个人资料',
  search:'搜索',
  Latest_Events:"最新事件",

  el: {
    colorpicker: {
      confirm: '确定',
      clear: '清空'
    },
    datepicker: {
      now: '此刻',
      today: '今天',
      cancel: '取消',
      clear: '清空',
      confirm: '确定',
      selectDate: '选择日期',
      selectTime: '选择时间',
      startDate: '开始日期',
      startTime: '开始时间',
      endDate: '结束日期',
      endTime: '结束时间',
      prevYear: '前一年',
      nextYear: '后一年',
      prevMonth: '上个月',
      nextMonth: '下个月',
      year: '年',
      month1: '1 月',
      month2: '2 月',
      month3: '3 月',
      month4: '4 月',
      month5: '5 月',
      month6: '6 月',
      month7: '7 月',
      month8: '8 月',
      month9: '9 月',
      month10: '10 月',
      month11: '11 月',
      month12: '12 月',
      // week: '周次',
      weeks: {
        sun: '日',
        mon: '一',
        tue: '二',
        wed: '三',
        thu: '四',
        fri: '五',
        sat: '六'
      },
      months: {
        jan: '一月',
        feb: '二月',
        mar: '三月',
        apr: '四月',
        may: '五月',
        jun: '六月',
        jul: '七月',
        aug: '八月',
        sep: '九月',
        oct: '十月',
        nov: '十一月',
        dec: '十二月'
      }
    },
    select: {
      loading: '加载中',
      noMatch: '无匹配数据',
      noData: '无数据',
      placeholder: '请选择'
    },
    cascader: {
      noMatch: '无匹配数据',
      loading: '加载中',
      placeholder: '请选择',
      noData: '暂无数据'
    },
    pagination: {
      goto: '前往',
      pagesize: '条/页',
      total: '共 {total} 条',
      pageClassifier: '页'
    },
    messagebox: {
      title: '提示',
      confirm: '确定',
      cancel: '取消',
      error: '输入的数据不合法!'
    },
    upload: {
      deleteTip: '按 delete 键可删除',
      delete: '删除',
      preview: '查看图片',
      continue: '继续上传'
    },
    table: {
      emptyText: '暂无数据',
      confirmFilter: '筛选',
      resetFilter: '重置',
      clearFilter: '全部',
      sumText: '合计'
    },
    tree: {
      emptyText: '暂无数据'
    },
    transfer: {
      noMatch: '无匹配数据',
      noData: '无数据',
      titles: ['列表 1', '列表 2'],
      filterPlaceholder: '请输入搜索内容',
      noCheckedFormat: '共 {total} 项',
      hasCheckedFormat: '已选 {checked}/{total} 项'
    },
    image: {
      error: '加载失败'
    },
    pageHeader: {
      title: '返回'
    },
    popconfirm: {
      confirmButtonText: '确定',
      cancelButtonText: '取消'
    }
  },
  label: {
    UserCenter: "User Center", // 用户中心
  },
  // 按钮
  button: {
    ReadMore: "阅读更多",
    Ok: "确定", // 确定
    Cancel: "取消", // 取消
    Submit: "提交", // 提交
    Edit: "编辑", // 编辑
    Add: "新增", // 新增
    Delete: "删除", // 删除
    Hide: "隐藏", // 隐藏
    Download: "下载", // 下载
    Copy: "复制", // 复制
    Generate: "生成", // 生成
    Zoom_in: "放大", // 放大
    Zoom_out: "缩小", // 缩小
    Full: "全屏", // 全屏
    Reset: "重置", // 重置
    Search: "搜索", // 搜索
    Clear: "清空", // 清空
    Like: "点赞", // 点赞
    Favorite: "收藏", // 收藏
    Login: "登录", // 登录
    Logout: "退出", // 退出
    Register_For_Free: "免费注册", // 免费注册
    Mobile: "移动端", // 移动端
    Scan: "扫描手机上的二维码以浏览IKCEST", // 扫描手机上的二维码以浏览IKCEST
    More: "更多", // 更多
    Print: "打印", // 打印
    Correct: "纠错" // 纠错
  },
  layer: {
    Toggle: "Toggle",
    Tips: "Tips"
  },
  footer: {
    Sitemap: "网站地图", // 网站地图
    Contact: "联系", // 联系
    About_IKCEST: "关于 IKCEST" // 关于 IKCEST
  }
};