<template>
  <div class="header-wrap">
    <div class="header-top">
      <!-- <router-link :to="{ path: '/' }"> -->
      <a :href="configServer.ikcestMasterStation + 'index.htm'" target="_blank">
        <!-- <img
          src="https://cdn-v1.ikcest.org/image/2021-06-01/T8BMadK2HEArEaQHNWThkisS24bSHJFE.png"
          width="300"
          class="cursor"
        /> -->
        <img src="https://cdn-v1.ikcest.org/images/1103/4067/4501/8682076a5ea4501cbc00adb8ba431cb1_0_0.png" width="300" class="cursor" /> 
        <!-- </router-link> -->
      </a>
      <div class="navBox">
        <p
          :class="{ isSelected: activeIndex === item.url }"
          :key="index"
          @click="handleRouterLink(item)"
          v-for="(item, index) in projectNav"
        >
          <span>{{ $t(item.name) }}</span>
          <i></i>
        </p>
      </div>
      <!-- <div class="navSearch">
        <input
          type="text"
          :placeholder="$t('search')"
          v-model="inputValue"
          class="search"
          :class="{ inputShow: show }"
        />
        <img src="../../assets/img/header/search.png" alt="" @click="search" />
      </div> -->

      <div class="langBox">
        <!-- <span
          class="langTitle"
          :class="{ selected: $i18n.locale === 'cn' }"
          @click="toggleLang('cn')"> -->
        <!-- 中文 -->
        <!-- </span -->
        <!-- > -->
        <!-- <span
          class="langTitle"
          :class="{ selected: $i18n.locale === 'en' }"
          @click="toggleLang('en')"> -->
        <!-- English -->
        <!-- </span> -->
        <!-- 没有用户名 登录 -->
        <template v-if="!userInfo.userName">
          <span class="langTitle marginLeft30" @click="handleSign(0)">{{
            $t("signin")
          }}</span>
          <!-- 注册 -->
          <span class="langTitle" @click="handleSign(1)">{{
            $t("signup")
          }}</span>
        </template>
        <!-- 有登录信息 -->
        <template v-else>
          <el-popover
            placement="top-start"
            width="250"
            trigger="hover"
            class="marginLeft20"
          >
            <div class="levelBox">
              <div class="userImg">
                <img
                  v-if="!userInfo.profile"
                  src="@/assets/img/user.png"
                  alt=""
                  class="imgOne"
                />
                <img
                  v-if="userInfo.profile"
                  :src="userInfo.profile"
                  alt=""
                  class="imgOne"
                />
                <div class="marginLeft20">
                  <p>
                    <span class="fontSize16">{{ userInfo.userName }}</span>
                  </p>
                  <!-- @click="openURL('https://origination-v2.ikcest.org/')" -->
                  <el-button
                    type="text"
                    @click="openURL('https://origination-v2.ikcest.org/')"
                    >{{ $t("label.UserCenter") }}</el-button
                  >
                </div>
              </div>
              <!-- 退出登录 -->
              <el-button class="btn" @click="handleLogout">{{
                $t("button.Logout")
              }}</el-button>
            </div>
            <el-button
              slot="reference"
              type="text"
              @click="openURL('https://c2c-v2.ikcest.org/')"
            >
              <!-- @click="openURL('https://origination-v2.ikcest.org/')" -->
              <i class="iconfont iconUserCenter marginRight5"></i
              >{{ userInfo.userName }}
            </el-button>
          </el-popover>
        </template>
      </div>

      <el-select
        v-model="langValue"
        :placeholder="$t('PleaseSelect')"
        style="width: 120px"
      >
        <el-option
          v-for="item in langOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <!--    退出确认框-->
    <LogOut :visible="dialogVisible" />
  </div>
</template>

<script>
import store from "@/store";
import { GlobalMixin } from "@/mixins/globalMixin";
import LogOut from "@/components/LogOut/index";
import axios from "axios";
import Cookies from "js-cookie";
import configServer from "@/config";

export default {
  name: "Header",
  mixins: [GlobalMixin],
  components: { LogOut },
  data() {
    return {
      configServer: configServer,
      projectNav: [
        {
          name: "Home", // 术语关联词配置
          url: "/",
        },
        {
          name: "News",
          url: "/News",
        },
        {
          name: "Publications",
          url: "/Publications",
        },
        {
          name: "Reports",
          url: "/Reports",
        },
        {
          name: "Events",
          url: "/Events",
        },
        {
          name: "Centres",
          url: "/Centres",
        },
      ],
      inputValue: "",
      dialogVisible: false,
      userInfo: store.getters.userInfo,
      show: true,
      langValue: "",
      langOptions: [
        {
          label: "English",
          value: "en",
        },
        {
          label: "中文",
          value: "zh",
        },
        {
          label: "русск",
          value: "rs",
        },
        {
          label: "français",
          value: "fr",
        },
        {
          label: "español",
          value: "es",
        },
        {
          label: "ربية",
          value: "ab",
        },
      ],
    };
  },
  computed: {
    /** 计算 当前路径*/
    activeIndex() {
      return this.$route.path;
    },
  },
  watch: {
    "store.getters.userInfo"(o, n) {
      this.userInfo = n;
    },
    langValue(val) {
      this.$emit("langSwit", val);
    },
  },

  created() {
    this.hasLogin();
  },
  mounted() {
    let rel = Cookies.get("reload");
    if (this.becomeAuthor && (!rel || rel == 0)) {
      Cookies.set("reload", 1);
      window.location.reload();
    }
    this.mastUrl = process.env.VUE_APP_API_IKCESTMASTERSTANTION;

    this.$nextTick(() => {
      this.langValue = Cookies.get("language") || "en";
    });
  },
  methods: {
    async hasLogin() {
      let passportid = store.getters.passport || Cookies.get("passport");
      const res = await axios.get(
        configServer.passportUrl +
          "passport/checkSSO.do?passport=" +
          passportid,
        {
          withCredentials: true, // 表明是否跨域请求,证书
        }
      );
    
      if (
        res &&
        res.data.code === 200 &&
        JSON.stringify(res.data.messages.data) !== "{}"
      ) {
        this.userInfo = res.data.messages.data.user;
        // 若已登录，则存 userInfo 和 passport 信息
        store.commit("user/SET_PASSPORT", res.data.messages.data.session.id);
        store.commit("user/SET_USERINFO", res.data.messages.data.user);
      }
    },
    /** 路由跳转*/
    handleRouterLink(item) {
      if (item.type) {
        window.open(item.url, "_blank");
      } else {
        this.$router.push({ path: item.url });
      }
    },
    handleLogout() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    /** 登录/注册*/
    handleSign(num) {
      let current = window.location.href;
      let link;
      if (num === 0) {
        // link = "configServer.ikcestMasterStationmy/login.htm?returnUrl=";
        link = configServer.ikcestMasterStation + "my/login.htm?returnUrl=";
      } else {
        // link = "configServer.ikcestMasterStationmy/register.htm?returnUrl=";
        link = configServer.ikcestMasterStation + "my/register.htm?returnUrl=";
      }
      window.location.href = link + encodeURIComponent(current);
    },
    openURL(url) {
      window.location.href = url;
    },

    search() {
      this.show = !this.show;
      if (this.inputValue !== "") {
        // let url=`http://ikcest.org/resource.htm?keyword=${this.inputValue}&paramTypeTop=&theme=c2c`
        let url = `configServer.ikcestMasterStationresource.htm?keyword=${this.inputValue}&paramTypeTop=&theme=c2c`;
        window.open(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/*头部布局*/
.header-wrap {
  position: fixed;
  height: 80px;
  width: 100%;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  .header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-width: 1130px;
    max-width: 1200px;
    margin: 0 auto;
    img{
      width: 120px;
      height: 80px;
    }
  }
}
/*语言选择*/
.langBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  .langTitle {
    font-size: 14px;
    cursor: pointer;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    width: 76px;

    &:hover {
      color: #009fe3;
    }
  }
  .langTitle:nth-child(2n-1) {
    &:after {
      content: "|";
      margin: 0 10px;
      color: #999;
    }
  }
  .selected {
    color: #009fe3;
  }
}
/*头部导航*/
.navBox {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  list-style: none;
  p {
    height: 80px;
    padding: 30px 7px;
    text-align: center;
    cursor: pointer;
    span {
      font-weight: 700;
      font-size: 14px;
      color: #444;
      &:hover {
        color: #009fe3;
      }
    }
    i {
      margin: 8px auto 0 auto;
      display: block;
      width: 4px;
      height: 4px;
      background: transparent;
      border-radius: 50%;
    }
  }
  .isSelected {
    i {
      background: #009fe3;
    }
  }
}

// 头部搜索
.navSearch {
  // background-color: red;
  input {
    width: 86px;
    height: 24px;
    border: none;
    border-bottom: 1px solid rgba(63, 99, 120, 0.3);
    padding-right: 5px;
    font-size: 14px;
    outline: none;
    margin-right: 5px;
    vertical-align: middle;
  }
  img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    cursor: pointer;
  }
}

// 搜索框隐藏
.inputShow {
  visibility: hidden;
}

.levelBox {
  .userImg {
    display: flex;
    justify-content: flex-start;
    padding: 20px 12px 0 12px;
    .imgOne {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid #eee;
    }
    .imgTwo {
      margin-right: 5px;
      width: 20px;
      height: auto;
    }
    p {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .el-button {
      margin-top: -10px;
    }
  }
  .progressBox {
    width: 100%;
    padding: 10px 15px;
    ::v-deep .el-progress-bar {
      margin-right: 0;
      padding-right: 0;
    }
    ::v-deep .el-progress-bar__outer {
      overflow: unset;
    }
    ::v-deep .el-progress-bar__outer {
      background: #eeeeee;
    }
    ::v-deep .el-progress-bar__inner {
      background: linear-gradient(90deg, #0093dd, #2501db);
      &:after {
        position: absolute;
        top: -5px;
        right: -1px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #2501db;
      }
    }
  }
  .buttonList {
    margin-bottom: 10px;
    padding: 0 10px;
    list-style: none;
    li {
      i {
        margin-right: 5px;
        color: #0093dd;
      }
      .el-button {
        color: #555;
        height: 20px;
      }
    }
  }
  .btn {
    display: block;
    margin-top: 10px;
    width: 100%;
    background: #f6f6f6;
    border: none;
  }
}
</style>
