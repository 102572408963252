import { MessageBox } from "element-ui";

export const GlobalMixin = {
  data() {
    return {
      historyList: [] // 历史搜索记录，存本地
    };
  },
  watch: {
    "$i18n.locale"(newType) {
      this.formatLanguageType(newType);
    }
  },
  created() {
    // if (store.getters.userInfo) {
    //   this.userInfo = store.getters.userInfo;
    //   console.log("userInfo...", this.userInfo);
    // }
    /** 先判断，赋值*/
    this.getLocalKey();
  },
  mounted() {},
  methods: {
    handleRouter(path, id) {
      this.$router.push({ path: path, query: { id: id } });
    },
    /** 语言切换*/
    toggleLang(lang) {
      localStorage.setItem("locale", lang); // 设置 localStorage
      this.$i18n.locale = localStorage.getItem("locale"); // 设置 locale
    },
    /** 登录方法*/
    Login() {
      let tip =
        this.$i18n.locale === "cn"
          ? "您暂未登录，请先登录！"
          : "You are not logged in, please log in first!";
      MessageBox.confirm(tip, this.$i18n.locale === "cn" ? "提示" : "Tips", {
        confirmButtonText: this.$i18n.locale === "cn" ? "登录" : "Login",
        cancelButtonText: this.$i18n.locale === "cn" ? "取消" : "Cancel",
        type: "warning",
        closeOnClickModal: false
      })
        .then(() => {
          let current = window.location.href;
          window.location.href =
            "https://www.ikcest.org/my/login.htm?returnUrl=" +
            encodeURIComponent(current);
        })
        .catch(() => {});
    },
    /** 设置历史搜索记录，在localStorage中 保存 historyList*/
    setLocalHistory() {
      /** 先判断*/
      this.getLocalKey();
      /** 搜索历史*/
      // 没有搜索记录，把搜索值push进数组首位，存入本地
      if (!this.historyList.includes(this.value)) {
        this.historyList.unshift(this.value);
        localStorage.setItem("historyList", JSON.stringify(this.historyList));
      } else {
        //有搜索记录，删除之前的旧记录，将新搜索值重新push到数组首位
        let i = this.historyList.indexOf(this.value);
        this.historyList.splice(i, 1);
        this.historyList.unshift(this.value);
        localStorage.setItem("historyList", JSON.stringify(this.historyList));
      }
      this.$forceUpdate();
    },
    /** 判断 localStorage中 是否有 historyList*/
    getLocalKey() {
      //如果本地存储的数据historyList有值，直接赋值给data中的historyList
      if (JSON.parse(localStorage.getItem("historyList"))) {
        this.historyList = JSON.parse(localStorage.getItem("historyList"));
      } else {
        this.historyList = [];
      }
    },
    /** 清空历史搜索记录 */
    empty() {
      this.$message({
        type: "success",
        message: "清空历史搜索成功"
      });
      localStorage.removeItem("historyList");
      this.historyList = [];
    },
    /** language 语言转换 中英文匹配*/
    formatLanguageType(type) {
      if (this.$i18n.locale === "cn") {
        switch (type) {
          case "cn":
            return "中文";
            break;
          case "ar":
            return "阿拉伯语";
            break;
          case "de":
            return "德语";
            break;
          case "ru":
            return "俄语";
            break;
          case "pt":
            return "葡萄牙语";
            break;
          case "fr":
            return "法语";
            break;
          case "it":
            return "意大利语";
            break;
          case "jp":
            return "日语";
            break;
          case "es":
            return "西班牙语";
            break;
          case "kr":
            return "韩语";
            break;
          case "en":
            return "英语";
            break;
        }
      } else {
        switch (type) {
          case "cn":
            return "中文";
            break;
          case "ar":
            return "اللغة";
            break;
          case "de":
            return "Deutsch";
            break;
          case "ru":
            return "Русский";
            break;
          case "pt":
            return "Português";
            break;
          case "fr":
            return "Français";
            break;
          case "it":
            return "Italiano";
            break;
          case "jp":
            return "日本語";
            break;
          case "es":
            return "Español";
            break;
          case "kr":
            return "한국어";
            break;
          case "en":
            return "English";
            break;
        }
      }
    },

    /** 当我们浏览器缩放的时候，echart图表也等比例缩放*/
    windowResize(myChart) {
      window.addEventListener("resize", function() {
        // 让我们的图表调用 resize这个方法
        myChart.resize();
      });
    }
  }
};
