
var state = {
  passport: null,
  userInfo: {
    userName: null,
    profile: null
  },
  languageType: null,
  hasLike: null,
  hasCollect: 0,
  likeCount: null,
};
const mutations = {
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_PASSPORT: (state, passport) => {
    state.passport = passport;
  },
  SET_LANGUAGETYPE: (state, languageType) => {
    state.languageType = languageType;
  },
  SET_HASLIKE: (state, hasLike) => {
    state.hasLike = hasLike;
  },
  SET_HASCOLLECT: (state, hasCollect) => {
    state.hasCollect = hasCollect;
  },
  SET_HASLIKECOUNT: (state, likeCount) => {
    state.likeCount = likeCount;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
