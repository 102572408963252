import Vue from "vue";

// function dateFormat (time, format) {
//     var t = new Date(time)
//     var tf = function (i) {
//       return (i < 10 ? '0' : '') + i
//     }
//     return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
//       switch (a) {
//         case 'yyyy':
//           return tf(t.getFullYear())
//         // break
//         case 'MM':
//           return tf(t.getMonth() + 1)
//         // break
//         case 'mm':
//           return tf(t.getMinutes())
//         // break
//         case 'dd':
//           return tf(t.getDate())
//         // break
//         case 'HH':
//           return tf(t.getHours())
//         // break
//         case 'ss':
//           return tf(t.getSeconds())
//         // break
//       }
//     })
//   }
//    


function formatDate(date) {
  var arr = date.split("T");
  var d = arr[0];
  var darr = d.split('-');
  var t = arr[1];
  var tarr = t.split('.000');
  var marr = tarr[0].split(':');
  var dd = parseInt(darr[0]) + "/" + parseInt(darr[1]) + "/" + parseInt(darr[2]) + " " + parseInt(marr[0]) + ":" + parseInt(marr[1]) + ":" + parseInt(marr[2]);
  return formatDateTime(dd);
}

function formatDateTime(date) {
if(date.length<5){
  return date
}else{

  let time = new Date(Date.parse(date));
  time.setTime(time.setHours(time.getHours() + 8));
  let Y = time.getFullYear() + '-';
  let M = addZero(time.getMonth() + 1) + '-';
  let D = addZero(time.getDate()) + ' ';
  let h = addZero(time.getHours()) + ':';
  let m = addZero(time.getMinutes()) + ':';
  let s = addZero(time.getSeconds());
  return Y + M + D;
}
}

function addZero(num) {
  return num < 10 ? '0' + num : num;
}


// /* 数字金额逢三加， 比如 123,464.23 */
function numberToCurrency(value) {
  if (!value) return '0'
  // 将数值截取，保留两位小数
  // value = value.toFixed(2)
  value = Math.ceil(value)
  // 获取整数部分
  const intPart = Math.trunc(value)
  // 整数部分处理，增加,
  const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  // 预定义小数部分
  // let floatPart = '.00'
  // 将数值截取为小数部分和整数部分
  const valueArray = value.toString().split('.')
  if (valueArray.length === 2) { // 有小数部分
    floatPart = valueArray[1].toString() // 取得小数部分
    return intPartFormat 
    // + '.' + floatPart
  }
  return intPartFormat 
  // + floatPart
}






// formatDatePoint

function formatDatePoint(date) {
  let time = new Date(Date.parse(date));
  time.setTime(time.setHours(time.getHours() + 8));
  // let Y = time.getFullYear() + '.';
  let Y = time.getFullYear() + ' ';
  // let M = addZero2(time.getMonth() + 1);
  let M = addZero2(time.getMonth() + 1) + '.';
  let D = addZero2(time.getDate()) + ' ';
  let h = addZero2(time.getHours()) + ':';
  let m = addZero2(time.getMinutes()) + ':';
  let s = addZero2(time.getSeconds());
  return Y + M + D;
  // return Y + M ;
}
function addZero2(num) {
  return num < 10 ? '0' + num : num;
}



Vue.filter("formatDateTime",formatDateTime);
Vue.filter("formatDatePoint",formatDatePoint);
Vue.filter("numberToCurrency",numberToCurrency);