<template>
  <div id="app">
    <!-- <Header1 /> -->
    <Header @langSwit="langSwitfN"/>
    <router-view />
    <Footer />
  </div>
</template>
<script>
// import Header1 from "./components/Header1";
import Footer from "./components/Footer";
import Header from "@/components/Header";
import Cookies from 'js-cookie'

export default {
  components: {
    Header,
    Footer,
  },
  methods: {
    langSwitfN(val) {
      this.$i18n.locale = val || "en";
      if (val && val != "") {
        Cookies.set("language", val,{ secure: true });
      }
    },
  }
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  // color: #222323;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
a {
  color: black;
  text-decoration: none;
  cursor: pointer !important;
}

ul,
li {
  list-style: none;
}
.container {
  font-size: 16px;
  font-family: Helvetica;
  color: #000000;
}

img {
  border: none;
}

// 边框和阴影
.bordershadow {
  box-shadow: 0px 8px 8px -2px rgba(21, 71, 93, 0.05);
  background-color: #ffffff;
  padding: 15px 15px 0px;
}


//2行文字标题,行高20
.p2rowline20 {
  width: 100%;
  height: 42px;
  padding-right: 5px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; // 控制多行的行数
  -webkit-box-orient: vertical;
}


//描述1行
.desc {
  height: 18px;
  font-size: 14px;
  font-weight: 400;
  color: #666768;
  line-height: 18px;
  padding-right: 5px;
  margin-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; // 控制多行的行数
  -webkit-box-orient: vertical;
}


// 新闻内容
.newsUlContainer {
  li + li {
    border-top: 1px solid #dde0e1;
  }
}

.newsItem {
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  .newsItem-img img {
    width: 120px;
    height: 88px;
  }
}

.newsItemNoImg {
  padding: 10px 0px;
}

// 标题行
.titleRow {
  height: 30px;
  line-height: 30px;
  // border: 1px solid red;
  display: flex;
  justify-content: space-between;
  .title {
    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      line-height: 30px;
      border-radius: 50%;
      border: 3px solid #009fe3;
      margin-right: 5px;
      margin-bottom: -2px;
    }
  }
  .more {
    cursor: pointer;
    a {
      color: #009fe3;
    }
  }
}

// 日期样式
.dateTime {
  font-size: 12px;
  font-weight: 400;
  color: #98999a;
  padding: 10px 0px;
}

// 2行文字
.p2row {
  width: 100%;
  height: 51px;
  padding-right: 5px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; // 控制多行的行数
  -webkit-box-orient: vertical;
}

//2行文字标题,行高20
.p2rowline20 {
  width: 100%;
  height: 42px;
  padding-right: 5px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; // 控制多行的行数
  -webkit-box-orient: vertical;
}

// 分页
.pagination {
  margin-top: 20px;
  ::v-deep .el-pagination.is-background .btn-prev {
    background-color: white;
  }
  ::v-deep .el-pagination.is-background .btn-next {
    background-color: white;
  }
  // 修改页数默认背景颜色
  ::v-deep .el-pagination.is-background .el-pager li {
    background-color: white;
  }
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #409eff; //修改选中项背景
  }
}

//1行描述
.desc {
  height: 18px;
  font-size: 14px;
  font-weight: 400;
  color: #666768;
  line-height: 18px;
  padding-right: 5px;
  margin-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; // 控制多行的行数
  -webkit-box-orient: vertical;
}

</style>
