<!--
 * @Author: wenju.liu wenju.liu@softline.sh.cn
 * @Date: 2022-07-25 11:08:19
 * @LastEditors: wenju.liu wenju.liu@softline.sh.cn
 * @LastEditTime: 2022-07-25 13:17:40
 * @FilePath: /ikcest-c2c/src/components/LogOut/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <!--    退出确认框-->
  <el-dialog
          :visible.sync="visible"
          width="30%"
          :before-close="handleClose"
          :show-close="false"
          class="tipDialog"
          :append-to-body="true"
          :close-on-click-modal="false"
  >
    <div class="tipBox">
      <img src="@/assets/img/tip.jpg" alt="" width="120" height="auto">
      <span class="fontWeight500">{{$i18n.locale ==='cn' ? '确定退出？':'Confirm log out?'}}</span>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button  @click="cancel()">{{$t('button.Cancel')}}</el-button>
      <el-button type="primary" @click="logOut()">{{$i18n.locale ==='cn' ? '确定':'Sure'}}</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import store from "@/store";
  import { getLogout } from "@/api/user-center";

  import { GlobalMixin } from '@/mixins/globalMixin'
export default {
  name: "LogOut",
  props: ['visible'],
  mixins: [GlobalMixin],
  data() {
    return {
    }
  },
  computed: {
  },
  created() {

  },
  mounted() {
  },
  methods: {
    //6月9日新增cancel()
    cancel() {
      this.visible = false;
      history.go(0); //刷新当前页面
    },
    handleLogout() {
      this.visible = true;
    },
    handleClose() {
      this.visible = false;
    },
    openURL(url) {
      window.location.href = url
    },
    /** 退出方法*/
    async logOut() {
      const res = await getLogout();
      if (res) {
        store.commit("user/SET_PASSPORT", null);
        store.commit("user/SET_USERINFO", {});
        localStorage.setItem('userId',res.data.messages.data.session.id);
        localStorage.setItem('userInfo', res.data.messages.data.user);
        this.$message({
          showClose: true,
          message: 'You have successfully logged out!',
          type: 'success'
        });
        window.location.reload();
      }
    },
  }
};
</script>

<style lang="scss" scoped>
  .tipDialog{
    z-index: 200000;
    ::v-deep .el-dialog__header{ padding: 0;}
    .tipBox{
      text-align: center;
      img{
        display: block;
        margin: 0 auto;
      }
    }
    .dialog-footer{
      display: flex;
      .el-button{ flex: 1;}
    }
  }
</style>
