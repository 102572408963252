import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import Cookies from 'js-cookie'
import App from './App.vue'
import router from './router'
// import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import "./filter"
import echarts from 'echarts'
import VueI18n from "vue-i18n";

import enLocale from "@/assets/i18n/en";
import zhLocale from "@/assets/i18n/zh-CN";
import rsLocale from "@/assets/i18n/rs";
import esLocale from "@/assets/i18n/es";
import frLocale from "@/assets/i18n/fr";
import abLocale from "@/assets/i18n/ab";

// import enLocaleE from "../node_modules/element-ui/lib/locale/lang/en";
// import zhLocaleE from "../node_modules/element-ui/lib/locale/lang/zh-CN";
import store from "./store";
import './swiper';
import VueAMap from 'vue-amap';

// axios.defaults.baseURL='/appp'
axios.defaults.baseURL='/api';//发布时需要启用
Vue.prototype.$echarts = echarts;
Vue.use(VueAxios, axios)
Vue.use(VueI18n)

Vue.config.productionTip = false
Vue.use(ElementUI);

Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: 'b0dd4de6be9043d8ab155e9b461d05c4',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch','AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.Geolocation','AMap.Geocoder', 'AMap.AMapManager', 'AMap.Marker'],
  v: '1.4.4'
});


// 语言包
// const messages = {
//   en: {
//     message: "hello",
//     ...enLocale,
//     ...enLocaleE
//   },
//   cn: {
//     message: "你好",
//     ...zhLocale,
//     ...zhLocaleE
//   }
// };

const messages = {
  en: {
    message: 'hello',
    ...enLocale
  },
  zh: {
    message: 'hello',
    ...zhLocale
  },
  rs: {
    message: 'hello',
    ...rsLocale
  },
  fr: {
    message: 'hello',
    ...frLocale
  },
  es: {
    message: 'hello',
    ...esLocale
  },
  ab: {
    message: 'hello',
    ...abLocale
  }
}
// Create VueI18n instance with options
// const i18n = new VueI18n({
//   locale: localStorage.getItem("locale") || "en", // 语言标识
//   messages, // set locale messages
//   silentTranslationWarn: true
// });
const i18n = new VueI18n({
  locale: Cookies.get('language') || 'en', // set locale
  messages, // set locale messages
  silentTranslationWarn: true
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
