<template>
  <div class="home-page">
    <div>
      <!--f1 UNESCO Centres开始 -->
      <div class="unesco bordershadow">
        <!-- 栏目标题开始 -->
        <div class="titleRow">
          <div class="title">{{ $t("UNESCO_Centres") }}</div>
          <div class="more">
            <router-link to="/Centres">
              {{ $t("More") }}
            </router-link>
          </div>
        </div>
        <!-- 栏目标题结束 -->
        <!-- UNESCO Centres内容开始 -->
        <div>
          <!-- UNESCO Centres左侧开始 -->
          <div class="unesco-left">
            <!-- 左侧地图开始 -->
            <div class="map">
              <!-- 地图组件开始 -->
              <div class="maps">
                <div class="amap-page-container">
                  <div class="toolbar">
                    <!-- 当前坐标: {{ lng }}, {{ lat }} lang="en" -->
                  </div>
                  <el-amap
                    vid="amapDemo"
                    :center="center"
                    :zoom="zoom"
                    lang="en"
                    class="amap-demo"
                    :events="events"
                    pitch-enable="false"
                  >
                    <el-amap-marker
                      v-for="(marker, index) in markers"
                      :key="index"
                      :events="marker.events"
                      :position="marker.position"
                    />
                    <el-amap-info-window
                      v-if="window"
                      :position="window.position"
                      :visible="window.visible"
                      :content="window.content"
                      :offset="window.offset"
                      :close-when-click-map="true"
                      :is-custom="true"
                    >
                      <div id="info-window">
                        <p>{{ window.name }}</p>
                        <!-- <div class="detail" @click="checkDetail">查看详情</div> -->
                      </div>
                    </el-amap-info-window>
                  </el-amap>
                </div>
              </div>
              <!-- 地图组件结束 -->
            </div>

            <!-- 左侧地图结束 -->

            <div class="triangle"></div>
          </div>
          <!-- UNESCO Centres左侧结束 -->
          <!-- UNESCO Centres右侧开始 -->
          <div class="unesco-right">
            <div class="tabs">
              <ul>
                <li
                  @click="tabnum = 'Asia'"
                  :class="{ active: tabnum == 'Asia' }"
                >
                  <el-badge :value="item1total" class="item">
                    <div>
                      {{ $t("Asia") }}
                    </div>
                  </el-badge>
                </li>

                <li
                  @click="tabnum = 'Europe'"
                  :class="{ active: tabnum == 'Europe' }"
                >
                  <el-badge :value="item2total" class="item">
                    <div>
                      {{ $t("Europe") }}
                    </div>
                  </el-badge>
                </li>

                <li
                  @click="tabnum = 'America'"
                  :class="{ active: tabnum == 'America' }"
                >
                  <el-badge :value="item3total" class="item">
                    <div>
                      {{ $t("America") }}
                    </div>
                  </el-badge>
                </li>

                <li
                  @click="tabnum = 'Africa'"
                  :class="{ active: tabnum == 'Africa' }"
                >
                  <el-badge :value="item4total" class="item">
                    <div>
                      {{ $t("Africa") }}
                    </div>
                  </el-badge>
                </li>

                <li
                  @click="tabnum = 'Oceania'"
                  :class="{ active: tabnum == 'Oceania' }"
                >
                  <el-badge :value="item5total" class="item">
                    <div>
                      {{ $t("Oceania") }}
                    </div>
                  </el-badge>
                </li>
              </ul>
            </div>
            <!-- tab1开始 -->
            <!-- <div class="tab1" v-show="tabnum == 0"> -->
            <div class="tab1">
              <div class="box">
                <el-scrollbar>
                  <ul>
                    <li v-for="(item, index) of tabContent" :key="index">
                      <a
                        :target="
                          item.url == 'javascript:;' ? '_self' : '_blank'
                        "
                        @click="handleOpen(item.url)"
                        :style="
                          item.url == 'javascript:;' ? 'cursor:default' : ''
                        "
                      >
                        <!-- <a :href="item.url"> -->
                        <div class="tabrow2">
                          {{ item.name }}
                        </div>
                      </a>
                    </li>
                  </ul>
                </el-scrollbar>
              </div>
            </div>
            <!-- tab1结束 -->
          </div>
          <!-- UNESCO Centres右侧结束 -->
        </div>
        <!-- UNESCO Centres内容开始 -->
      </div>
      <!-- f1 UNESCO Centres结束 -->

      <!-- f2 News 和 Event Map 开始 -->
      <div class="f2">
        <div class="f2news bordershadow">
          <!-- 栏目标题开始 -->
          <div class="titleRow">
            <div class="title">{{ $t("News") }}</div>
            <div class="more">
              <router-link to="/News">
                {{ $t("More") }}
              </router-link>
            </div>
          </div>
          <!-- 栏目标题结束 -->
          <!-- 新闻列表开始 -->
          <div>
            <ul class="newsUlContainer" v-if="latestNews.length">
              <li v-for="(item, index) of latestNews" :key="index">
                <a @click="handleOpen(item.fromlink)" target="_blank">
                  <div class="newsItem">
                    <div>
                      <p class="p3row">
                        {{ item.title }}
                      </p>
                      <p class="dateTime">{{ item.date | formatDateTime }}</p>
                    </div>
                    <div class="newsItem-img">
                      <img :src="item.thumb" alt="" v-show="item.thumb" />
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <!-- 新闻列表结束 -->
        </div>
        <div class="event-map bordershadow">
          <div class="title-no-more">
            <span>{{ $t("Event_Map") }}</span>
          </div>
          <ul class="event-map-content" v-if="persons.length">
            <li
              class="event-map-item"
              v-for="(item, index) of persons"
              :key="index"
            >
              <router-link target="_blank" :to="`/Articles?id=${item.id}`">
                <div><img :src="item.avatar" alt="" /></div>
                <div>{{ item.name }}</div>
                <div>{{ item.education }}</div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <!-- f2 News 和 Event Map 结束 -->
      <!-- f3 Events开始 -->
      <!-- latestMeetings -->
      <div class="f3 bordershadow">
        <div class="titleRow">
          <div class="title">{{ $t("Events") }}</div>
        </div>
        <el-scrollbar class="f3event">
          <div v-if="latestMeetings">
            <!-- 上面开始 -->
            <div>
              <ul class="eventtop">
                <li v-for="(item, index) of eventsTop" :key="index">
                  <a @click="handleOpen(item.fromlink)" target="_blank">
                    <div>
                      <p class="city" style="display:none">
                        {{ item.address }}
                      </p>
                      <p class="time">
                        <span>{{ item.beginDate }}</span>
                        <span> - </span>
                        <span>{{ item.endDate }}</span>
                      </p>
                      <p class="theme">
                        {{ item.title }}
                      </p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <!-- 上面结束 -->
            <!-- 中间开始 -->
            <div>
              <ul class="eventmid">
                <li
                  class="year"
                  v-for="(item, index) of latestMeetings"
                  :key="index"
                >
                  <div>{{ item.beginDate | formatDatePoint }}</div>
                </li>
              </ul>
            </div>
            <!-- 中间结束 -->
            <!-- 下面开始 -->
            <div>
              <ul class="eventbot">
                <li v-for="(item, index) of eventsBot" :key="index">
                  <a @click="handleOpen(item.fromlink)" target="_blank">
                    <div>
                      <p class="theme">
                        {{ item.title }}
                      </p>
                      <p class="time">
                        <span>{{ item.beginDate }}</span>
                        <span> - </span>
                        <span>{{ item.endDate }}</span>
                      </p>
                      <p class="city" style="display:none">
                        {{ item.address }}
                      </p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <!-- 下面结束 -->
          </div>
        </el-scrollbar>
      </div>

      <!-- f3 Events结束 -->

      <!-- f4 Publications 和 Reports开始 -->
      <div class="f4">
        <!-- 左侧Publications开始 -->
        <div class="Publications bordershadow" v-if="latestPublications.length">
          <!-- 栏目标题开始 -->
          <div class="titleRow">
            <div class="title">{{ $t("Publications") }}</div>
            <div class="more">
              <router-link to="/Publications">
                {{ $t("More") }}
              </router-link>
            </div>
          </div>
          <!-- 栏目标题结束 -->
          <ul class="newsUlContainer">
            <li v-for="(item, index) of latestPublications" :key="index">
              <a @click="handleOpen(item.fromlink)" target="_blank">
                <div class="newsItem">
                  <div>
                    <p class="p2rowline20">
                      {{ item.title }}
                    </p>
                    <div class="desc">
                      {{ item.description }}
                    </div>
                    <p class="dateTime">{{ item.date | formatDateTime }}</p>
                  </div>
                  <div class="newsItem-img">
                    <img :src="item.thumb" alt="" />
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <!-- 左侧Publications结束 -->

        <!-- 右侧Reports开始 -->
        <div class="Reports bordershadow" v-if="latestReports.length">
          <!-- 栏目标题开始 -->
          <div class="titleRow">
            <div class="title">{{ $t("Reports") }}</div>
            <div class="more">
              <router-link to="/Reports">
                {{ $t("More") }}
              </router-link>
            </div>
          </div>
          <!-- 栏目标题结束 -->
          <ul class="newsUlContainer">
            <li v-for="(item, index) of latestReports" :key="index">
              <!-- <a :href="item.fromlink" target="_blank"> -->
              <a @click="handleOpen(item.fromlink)" target="_blank">
                <div class="newsItem">
                  <div>
                    <p class="p2rowline20">
                      {{ item.title }}
                    </p>
                    <div class="desc">
                      {{ item.description }}
                    </div>
                    <p class="dateTime">{{ item.date | formatDateTime }}</p>
                  </div>
                  <div class="newsItem-img">
                    <img :src="item.thumb" alt="" />
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <!-- 右侧Reports结束 -->
      </div>
      <!-- f4 Publications 和 Reports结束 -->
      <!-- f5 UNESCO Links 开始 -->
      <div class="f5 bordershadow">
        <div class="title-no-more">
          <span>{{ $t("UNESCO_Links") }}</span>
        </div>
        <ul>
          <li>
            <a
              @click="
                handleOpen('https://en.unesco.org/about-us/introducing-unesco')
              "
              target="_blank"
            >
              <div>
                Introducing UNESCO
              </div>
            </a>
          </li>
          <li>
            <a
              @click="handleOpen('https://en.unesco.org/countries')"
              target="_blank"
            >
              <div>
                List of Member States
              </div>
            </a>
          </li>
          <li>
            <a
              @click="
                handleOpen('https://en.unesco.org/countries/field-offices')
              "
              target="_blank"
            >
              <div>
                List of Field Offices
              </div>
            </a>
          </li>
          <li>
            <a
              @click="
                handleOpen(
                  'https://en.unesco.org/countries/national-commissions'
                )
              "
              target="_blank"
            >
              <div>
                National Commissions
              </div>
            </a>
          </li>
        </ul>
      </div>
      <!-- f5 UNESCO Links 结束 -->
    </div>
    <el-dialog
      v-if="dialogVisible"
      title="提示"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="600px"
    >
      <ALink :aUrl="linkUrl" @close="handleClose"></ALink>
    </el-dialog>
  </div>
</template>

<script>
import { addressCallback } from "../api/address.js";
export default {
  components: {
    ALink: () => import("@/components/ALink"),
  },
  data() {
    const self = this;
    return {
      dialogVisible: false,
      linkUrl: "22222",
      indexNews: [],
      persons: [],
      latestMeetings: [], //event
      eventsTop: [], //event里面上面的部分
      eventsYears: [], //event里面中间年份
      eventsBot: [], //event里面下面的部分

      latestNews: [], //news
      latestPublications: [],
      latestReports: [],
      tabnum: "Asia",
      tabContent: [], //tab内容
      // 气泡
      item1total: "", //Asia
      item2total: "", //Europe
      item3total: "", //America
      item4total: "", //Africa
      item5total: "", //Oceania
      Europe: [],
      America: [],
      Asia: [],
      Africa: [],
      Oceania: [],
      selectStates: [], //选中的州

      // 地图参数开始
      data: [
        {
          position: "13.707527700000014,45.7144379",
          name: "Italy",
        },
        {
          position: "117.09,32.43",
          name: "Santiago",
        },
      ],
      zoom: 5, //地图显示的缩放级别范围，在PC上，默认范围[3,18]，取值范围[3-18]；
      center: [13.707527700000014, 45.7144379], //地图中心点坐标值
      expandZoomRange: true,
      markers: [],
      windows: [],
      window: "",
      events: {
        click(e) {
          const { lng, lat } = e.lnglat;
          self.lng = lng;
          self.lat = lat;
        },
      },
      lng: 0,
      lat: 0,
      // 地图参数结束
    };
  },

  mounted() {
    this.getData();
    this.tabs();
    // this.point();
  },

  methods: {
    handleOpen(value) {
      this.dialogVisible = true;
      this.linkUrl = value;
    },
    handleClose() {
      this.dialogVisible = false;
    },

    getData() {
      // let url = "/data/api/c2cmeeting.do?action=list";
      // /data/api/c2cmeeting
      // let url = "http://192.168.4.193:82/data/c2cIndex.json";
      // let url = "/data/api/c2cIndex.do";
      let eventsBotValue = [];
      let eventsTopValue = [];
      let url = "/data/api/c2cIndex.json";
      this.axios.get(url).then((res) => {
        // console.log(res);
        let {
          indexNews,
          latestMeetings,
          latestNews,
          latestPublications,
          latestReports,
          persons,
        } = res.data.messages.data;
        this.indexNews = indexNews;
        this.latestNews = latestNews.slice(0, 3);
        this.latestPublications = latestPublications.slice(0, 4);
        this.latestReports = latestReports.slice(0, 4);
        this.persons = persons.slice(0, 4);
        // console.log( this.latestPublications, " this.latestPublications");
        // console.log(this.latestReports, "this.latestReports");
        this.latestMeetings = latestMeetings;
        // console.log( this.latestMeetings, "1111111rr11111111111");
        latestMeetings.forEach((item, index) => {
          if (index % 2 == 1) {
            eventsTopValue.push(item); //event里面上面的部分
          } else {
            eventsBotValue.push(item); //event里面下面的部分
          }
        });
        this.eventsTop = eventsTopValue;
        this.eventsBot = eventsBotValue;
        // console.log(this.eventsTop,"this.eventsTop")
        // console.log(this.eventsBot,"this.eventsBot")
      });
    },

    tabs() {
      addressCallback.forEach((item) => {
        if (item.continent == "Europe") {
          this.Europe.push(item);
        }
        if (item.continent == "America") {
          this.America.push(item);
        }
        if (item.continent == "Asia") {
          this.Asia.push(item);
        }
        if (item.continent == "Africa") {
          this.Africa.push(item);
        }
        if (item.continent == "Oceania") {
          this.Oceania.push(item);
        }
      });
      (this.item1total = this.Asia.length), //Asia
        (this.item2total = this.Europe.length), //Europe
        (this.item3total = this.America.length), //America
        (this.item4total = this.Africa.length), //Africa
        (this.item5total = this.Oceania.length); //Oceania
      // console.log(this.Europe,"pppppppppp")
      this.changeTab();
    },

    // tab切换 内容变化函数
    changeTab() {
      if (this.tabnum == "Europe") {
        this.tabContent = this.Europe;
      }
      if (this.tabnum == "America") {
        this.tabContent = this.America;
      }
      if (this.tabnum == "Asia") {
        this.tabContent = this.Asia;
      }
      if (this.tabnum == "Africa") {
        this.tabContent = this.Africa;
      }
      if (this.tabnum == "Oceania") {
        this.tabContent = this.Oceania;
      }
      this.center = [
        this.tabContent[0].addr.geometry.location.A,
        this.tabContent[0].addr.geometry.location.k,
      ];
      this.data = this.tabContent.map((item) => {
        return {
          position: `${item.addr.geometry.location.A},${item.addr.geometry.location.k}`,
          name: item.name,
        };
      });
      this.point();
      // console.log(this.center, "888888888this.center8888888888");
      // console.log(this.tabContent, "8888888888this.data888888888");
    },

    // 地图函数开始
    point() {
      const markers = [];
      const windows = [];
      const that = this;
      this.data.forEach((item, index) => {
        markers.push({
          position: item.position.split(","),
          // icon:item.url, //不设置默认蓝色水滴
          events: {
            mouseover() {
              // 方法：鼠标移动到点标记上，显示相应窗体
              that.windows.forEach((window) => {
                window.visible = false; // 关闭窗体
              });
              that.window = that.windows[index];
              that.$nextTick(() => {
                that.window.visible = true;
              });
            },
          },
        });
        windows.push({
          position: item.position.split(","),
          isCustom: true,
          offset: [115, 90], // 窗体偏移
          showShadow: false,
          visible: false, // 初始是否显示
          name: item.name,
        });
      });
      //  加点
      this.markers = markers;
      // 加弹窗
      this.windows = windows;
    },
    // checkDetail() {
    //   alert("点击了查看详情");
    // },
    // 地图函数结束
  },
  watch: {
    tabnum() {
      this.changeTab();
    },
  },
};
</script>
<style>
.el-scrollbar__wrap {
  overflow-x: hidden !important;
}
</style>

<style lang="scss" scoped>
.home-page {
  padding-top: 110px;
  width: 100%;
  min-width: 1200px;
  background-color: #f7f8f8;
  color: #444546;
  > div {
    width: 1200px;
    // border: 1px solid red;
    margin: 0 auto;
  }
}

.unesco {
  padding-bottom: 15px;
  > div:nth-child(2) {
    display: flex;
    justify-content: space-between;
  }
  > :nth-child(2) {
    margin-top: 10px;
  }
}

.unesco-left {
  width: 740px;
  height: 457px;
}

// 地图样式开始
.amap-demo {
  width: 730px;
  height: 457px;
  // padding-bottom: 15px;
}
.amap-page-container {
  position: relative;
}
#info-window {
  padding: 10px;
  margin-left: 30px;
  text-align: center;
  line-height: 21px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  .detail {
    width: 100%;
    height: 24px;
    color: #fff;
    background-color: #1a73e8;
    position: absolute;
    bottom: 0;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
  }
}
::v-deep .amap-copyright {
  display: none !important;
}

// 地图样式结束

.unesco-right {
  background-color: #f1f3f4;
  padding: 10px 10px;
}

.f3 {
  margin-top: 20px;
  height: 330px;
  box-shadow: 0px 8px 8px -2px rgba(21, 71, 93, 0.05);
  background-color: #ffffff;
}

.eventtop {
  display: flex;
  margin-top: 10px;
  li {
    width: 263px;
    height: 75px;
    // border: 1px solid red;
    margin-left: 67px;
    flex-shrink: 0;
    a {
      cursor: pointer;
    }
  }
  > li:first-child {
    margin-left: 165px;
  }
}

.eventmid {
  display: flex;
}

.year {
  flex-shrink: 0;
  width: 165px;
  height: 70px;
  // border: 1px solid red;
  margin: 8px 0px;
  position: relative;
  div {
    width: 67px;
    height: 67px;
    font-size: 16px;
    background: #009fe3;
    // border: 1px solid #3e484d;
    border-radius: 50%;
    margin-left: 97px;
    color: white;
    text-align: center;
    padding-top: 12px;
    line-height: 21px;
  }
}

.year div::before {
  content: ""; /*CSS伪类用法*/
  position: absolute; /*定位背景横线的位置*/
  top: 52%;
  display: inline-block;
  background: #009fe3; /*宽和高做出来的背景横线*/
  width: 104px;
  height: 2px;
}
.year div:before {
  left: 0px; /*调整背景横线的左右距离*/
}

.f3event {
  height: 270px;
}

.eventbot {
  display: flex;
  li {
    width: 263px;
    height: 75px;
    // border: 1px solid red;
    margin-right: 67px;
    flex-shrink: 0;
    a {
      cursor: pointer;
    }
  }
}

.city {
  width: 160px;
  padding: 0px 5px;
  height: 24px;
  line-height: 24px;
  background: #e7f6fd;
  text-align: center;
  font-size: 14px;
  margin: 0 auto;
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; // 控制多行的行数
  -webkit-box-orient: vertical;
}

.time {
  // margin-top: 10px;
  text-align: center;
  span {
    font-size: 12px;
    font-weight: 400;
    color: #98999a;
    line-height: 18px;
  }
}

.theme {
  // height: 37px;
  font-size: 14px;
  font-weight: 500;
  color: #222323;
  line-height: 18px;
  text-align: center;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; // 控制多行的行数
  -webkit-box-orient: vertical;
}

.tabs {
  ::v-deep .el-badge__content {
    // background: url("../assets/img/c2cimg/qp.png") no-repeat 0px 0px;
    border-radius: 5px;
    border: none;
    background-color: #fd9515;
    margin-top: -8px;
    margin-right: 30px;
    height: 20px;
    font-size: 14px;
    padding: 2px 6px;
  }
}

.tabs {
  > ul {
    display: flex;
    font-size: 24px;
    font-weight: bold;
    li {
      width: 80px;
      height: 32px;
      cursor: pointer;
      border: 1px solid #009fe3;
      // border: 1px solid red;
      text-align: center;
      margin-bottom: 16px;

      div {
        width: 80px;
        height: 32px;
        line-height: 30px;
      }
    }

    li + li {
      border-left: none;
    }
  }
}

.active {
  background-color: #009fe3 !important;
  div {
    color: #ffffff;
  }
}

.tab1 {
  .box {
    width: 403px;
    height: 388px;
    background: aliceblue;
  }
  .el-scrollbar {
    height: 100%;
  }
  .el-scrollbar__wrap {
    overflow: scroll;
    width: 110%;
    height: 120%;
  }
}

::v-deep .el-scrollbar__bar.is-horizontal {
  height: 8px;
}

// tab1样式
.tab1 {
  width: 403px;
  height: 388px;
  overflow: hidden;
  li {
    height: 58px;
    line-height: 21px;
    font-size: 14px;
    font-weight: 400;
    background-color: #ffffff;
    padding: 8px 6px;
  }
  li + li {
    margin-top: 8px;
  }
}

.tabrow2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; // 控制多行的行数
  -webkit-box-orient: vertical;
}

.f2 {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.f2news {
  width: 460px;
  height: 433px;
  // border: 1px solid red;
  margin-right: 15px;
}

.event-map {
  width: 725px;
  // height: 500px;
  // border: 1px solid red;
}

// 3行文字
.p3row {
  width: 100%;
  height: 72px;
  padding-right: 5px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; // 控制多行的行数
  -webkit-box-orient: vertical;
}

.title-no-more {
  height: 30px;
  line-height: 30px;
  > span {
    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      line-height: 30px;
      border-radius: 50%;
      border: 3px solid #009fe3;
      margin-right: 5px;
      margin-bottom: -2px;
    }
  }
}

.event-map-content {
  display: flex;
  justify-content: space-around;
}

.event-map-item {
  margin-top: 78px;
  flex: 0 0 25%;
  text-align: center;
  a {
    > div:first-child {
      img {
        width: 104px;
        height: 104px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    > div:nth-child(2) {
      font-size: 14px;
      font-weight: 500;
      color: #222323;
      line-height: 14px;
      margin-top: 12px;
      margin-bottom: 8px;
    }
    > div:last-child {
      // width: 159px;
      height: 42px;
      font-size: 14px;
      font-weight: 400;
      color: #666768;
      line-height: 21px;
    }
  }
}

.f4 {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.Publications {
  width: 592px;
  margin-right: 15px;
}

.Reports {
  width: 592px;
}

.f5 {
  margin-top: 20px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  > ul {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    li div {
      // width: 275px;
      background-color: #e7f6fd;
      color: #009fe3;
      // border: 1px solid red;
      text-align: center;
      line-height: 35px;
      height: 35px;
      font-size: 14px;
      padding: 0px 68px;
    }
  }
}

//可共用样式 驼峰

// 新闻内容
.newsUlContainer {
  li + li {
    border-top: 1px solid #dde0e1;
  }
}

.newsItem {
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  .newsItem-img > img {
    width: 120px;
    height: 88px;
    // object-fit:cover;
  }
}

.newsItemNoImg {
  padding: 10px 0px;
}

// 标题行
.titleRow {
  height: 30px;
  line-height: 30px;
  // border: 1px solid red;
  display: flex;
  justify-content: space-between;
  .title {
    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      line-height: 30px;
      border-radius: 50%;
      border: 3px solid #009fe3;
      margin-right: 5px;
      margin-bottom: -2px;
    }
  }

  .more {
    cursor: pointer;

    a {
      color: #009fe3;
    }
  }
}

// 日期样式
.dateTime {
  font-size: 12px;
  font-weight: 400;
  color: #98999a;
  padding: 10px 0px;
}

// 2行文字
.p2row {
  width: 100%;
  height: 51px;
  padding-right: 5px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; // 控制多行的行数
  -webkit-box-orient: vertical;
}

// 分页
.pagination {
  margin-top: 20px;
  ::v-deep .el-pagination.is-background .btn-prev {
    background-color: white;
  }
  ::v-deep .el-pagination.is-background .btn-next {
    background-color: white;
  }
  // 修改页数默认背景颜色
  ::v-deep .el-pagination.is-background .el-pager li {
    background-color: white;
  }
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #409eff; //修改选中项背景
  }
}
</style>
