import axios from "axios";
import store from "@/store";

const passportUrl = "https://prod-passport-v2.ikcest.org/";
const apiUrl = "http://192.168.2.193:82/data/api/engineering.do?action="

export function getPassportUserInfo() {
  return axios.get(
    passportUrl + "passport/checkSSO.do?passport=" + store.getters.passport,
    { withCredentials: true } // 表明是否跨域请求,证书
  );
}
export function getLogout() {
  return axios.get(passportUrl + "passport/logout.do", {
    withCredentials: true // 表明是否跨域请求,证书
  });
}
export function getHomeData(params) {
  return axios.get(apiUrl + "index", {
    params: params,
    withCredentials: true // 表明是否跨域请求,证书
  });
}
export function getExpertList(params) {
  return axios.get(apiUrl + "expertList", {
    params: params,
    withCredentials: true // 表明是否跨域请求,证书
  });
}
export function getResearchList(params) {
  return axios.get(apiUrl + "journalArticle", {
    params: params,
    withCredentials: true // 表明是否跨域请求,证书
  });
}
export function getLeftOption() {
  return axios.get(apiUrl + "getCategory", {
    withCredentials: true // 表明是否跨域请求,证书
  });
}
export function getMapData() {
  return axios.get(apiUrl + "map", {
    withCredentials: true // 表明是否跨域请求,证书
  });
}
export function getEngineerById(params) {
  return axios.get("https://prod-data-v2.ikcest.org/data/api/engineering.json?action=list2", {
    params: params,
    withCredentials: true // 表明是否跨域请求,证书
  });
}
